export default {
  countdown: {
    label: 'до начала акции:',
    days: ['день', 'дня', 'дней'],
    hours: ['час', 'часа', 'часов'],
    minutes: 'минут',
    seconds: 'секунд',
    untilStart: 'До начала акции',
    untilEnd: 'До конца акции',
  },
  hero: {
    description: 'комиссия на рассрочку снижается до 2 раз!',
    button: 'Получить лимит на рассрочку',
    promoInfo: 'Акция проходила с 14 по 21 июля',
  },
  features: [
    'Более 70 000 товаров в Alif Shop и магазинах партнеров',
    'Комиссия на рассрочку снижается до 2 раз',
    'Рассрочка от 6 до 24 месяцев',
    'Действует по всему Узбекистану',
  ],
  partners: {
    title: 'Список магазинов',
    link: 'Смотреть все магазины',
  },
  categories: {
    title: 'Какие товары можно купить?',
    link: 'Смотреть все товары',
    items: [
      {
        title: 'Смартфоны',
        price: 'от 99 000 сум/мес',
      },
      {
        title: 'Кондиционеры',
        price: 'от 199 000 сум/мес',
      },
      {
        title: 'Холодильники',
        price: 'от 119 000 сум/мес',
      },
      {
        title: 'Телевизоры',
        price: 'от 99 000 сум/мес',
      },
      {
        title: 'Стиральные машины',
        price: 'от 159 000 сум/мес',
      },
      {
        title: 'Ноутбуки',
        price: 'от 169 000 сум/мес',
      },
    ],
  },
  halal: {
    title: 'Халяльность Alif Nasiya',
    description:
      'Alif Nasiya предоставляет свои услуги по нормам и канонам шариата, что также говорит об открытости и честности сервиса.',
    button: 'Посмотреть сертификат',
  },
  family: {
    title: 'alif Juma разгружает семейные расходы',
    description: 'Оплачивайте товар ежемесячно, без нагрузки на ваш бюджет и живите легко',
    button: 'Узнать лимит покупок',
  },
  where: {
    title: 'Где покупать?',
    items: [
      {
        title: 'Онлайн на Alif Shop',
        points: ['Бесплатная доставка', 'Более 100 магазинов в одном месте'],
        button: 'Перейти в Alif Shop',
      },
      {
        title: 'В магазинах партнеров',
        points: ['Помощь консультанта', 'Более 2 тысяч магазинов наших партнеров по всему Узбекистану'],
        button: 'Перейти в список магазинов',
      },
    ],
  },
  reviews: {
    title: 'Отзывы наших клиентов',
    items: [
      {
        name: 'Sanobar Kenjayeva',
        date: '15 июня, 2023',
        text: 'Ассалому Алайкум. Я купила 3 телефона в Alif Shop. Есть много удобств. Рекомендую его всем.',
      },
      {
        name: 'Gukish',
        date: '29 мая, 2023',
        text: 'Все товары у вас оригиналы, качество отличное. Покупатель будет доволен вами',
      },
      {
        name: 'Gulchehra Inagamova',
        date: '11 мая, 2023',
        text: 'Пусть ваши клиенты увеличиваются, желаю вам успехов и успехов в вашем бизнесе.',
      },
      {
        name: 'Asadbek Ibrohimov',
        date: '19 апреля, 2023',
        text: 'Ассалому Алайкум. Я давно слежу за вами. Вы отвечаете всем, не ленясь. Удачи.',
      },
      {
        name: 'Davron Kamaev',
        date: '5 апреля, 2023',
        text: 'Ваши товары самые лучшие, один лучше другого.',
      },
    ],
  },
  mans: {
    title: 'Регистрируйтесь в Alif Nasiya и покупайте товары в рассрочку',
    button: 'Получить лимит на рассрочку',
  },
  faq: {
    title: 'Часто задаваемые вопросы',
    items: [
      {
        question: 'Что такое alif Juma?',
        answer:
          'alif Juma — это уникальная акция для приобретения товаров в рассрочку на выгодных условиях. Данная акция будет проходить с 14 по 21 июля.',
      },
      {
        question: 'Что такое лимит?',
        answer:
          'Лимит — это ваш баланс для покупки в рассрочку, с которым вы можете совершать покупки на сайте alifshop.uz или у наших партнёров-магазинов без ожидания и дополнительной проверки.',
      },
      {
        question: 'Как узнать свой лимит?',
        answer:
          'Лимит можно проверить сразу после его получения в личном кабинете на сайте alifnasiya.uz или в приложении Alif в разделе Nasiya.',
      },
      {
        question: 'Где совершать покупки?',
        answer:
          'Вы можете совершать покупки онлайн сразу на сайте alifshop.uz или же в магазинах наших партнёров, таких как IDEA, Mediaрark, Elmakon, T-Store и т.д.',
      },
      {
        question: 'Как поступить, если стоимость товара превосходит установленный лимит?',
        answer:
          'Если сумма товара превышает сумму выделенного вам лимита, то мы рассмотрим заявку на выбранный товар и ответим вам в ближайшие минуты.',
      },
      {
        question: 'Как я могу погасить рассрочку?',
        answer:
          'Сумма ежемесячного платежа автоматически списывается с вашей привязанной карты согласно установленному графику платежей. Тем не менее, вы всегда можете самостоятельно осуществить оплату через личный кабинет на сайте alifnasiya.uz или через платёжные системы Alif, Payme, Click и Paynet.',
      },
    ],
  },
  mobi: {
    title: 'Хотите получить от alif Juma ещё больше выгоды?',
    description:
      'Скачайте приложение Alif, пройдите регистрацию в Nasiya и получите 1 000 000 сум на бесплатные переводы',
  },
  contacts: {
    title: 'Остались вопросы?',
    description: 'Свяжитесь с нами!',
  },
  promoBanner: {
    button: 'Подробнее',
  },
  conditions: {
    title: 'Самые выгодные условия рассрочки',
  },
};
