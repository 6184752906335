export default {
  promotion: 'Акция',
  alifshopBanner: {
    title: 'Покупайте онлайн на Alif Shop',
    button: 'Перейти на Alif Shop',
  },
  banner: {
    title: 'Покупайте в рассрочку в {name}',
    description: 'Вы можете купить товары в рассрочку до 24 месяцев у различных партнёров по всему Узбекистану',
    button: 'Как купить?',
  },
  howToBuy: {
    title: 'Как купить в рассрочку',
    items: [
      {
        title: 'Пройдите регистрацию и получите лимит',
        button: 'Регистрация',
      },
      {
        title: 'Приходите в магазин партнеров и выберите товар',
        button: 'Все магазины',
      },
      {
        title: 'В онлайн-магазине Alif Shop',
        button: 'Перейти на Alif Shop',
      },
    ],
  },
  nearestShops: {
    title: 'Ближайшие магазины',
    button: 'Смотреть все',
    metresUnits: ['метр', 'метра', 'метров'],
    kilometresUnit: 'км',
    geolocationAllow: 'Разрешите доступ к геолокации, чтобы увидеть ближайшие магазины',
  },
  filter: 'Фильтр',
};
