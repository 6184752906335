export default {
  day: '0 дней | {count} день | {count} дня | {count} дней',
  month: '0 месяц | {count} месяц | {count} месяца | {count} месяцев',
  fields: {
    search: 'Поиск',
    passport: 'Серия и номер паспорта / ID-карты',
    phone: 'Номер телефона',
    password: 'Пароль',
    passwordRepeat: 'Повторите пароль',
    smsCode: 'Код из СМС',
    smsCodeExpire: 'Код действителен',
    name: 'Имя',
    birth: {
      date: 'Дата рождения',
      day: 'День',
      month: 'Месяц',
      year: 'Год',
    },
    male: 'Мужской',
    female: 'Женский',
    card: {
      label: 'Номер карты',
      expires: 'Срок карты',
      payment: 'Карта для оплаты',
      warning: 'Обратите внимание, что карта будет изменена для всех рассочек',
      mainCardChanged: 'Основная карта изменена',
      cardChangedForAll: 'Карта изменена для всех рассрочек',
      expirePlaceholder: 'мм/гг',
    },
    notFound: {
      title: 'Ничего не найдено',
      description: 'По вашему запросу ничего не найдено, попробуйте ввести другое слово',
    },
    selectValue: 'Выберите значение',
    fileSize: 'Размер файла превышает {value} МБ',
    noName: 'Без названия',
  },
  btns: {
    save: 'Сохранить',
    login: 'Вход',
    register: 'Зарегистрироваться',
    send: 'Отправить',
    personalCabinet: 'Личный кабинет',
    repeatSmsSend: 'Прислать повторно',
    restorePassword: 'Восстановить пароль',
    getSms: 'Получить код',
    next: 'Продолжить',
    setMainCard: 'Сделать основной',
    cardIsMain: 'Основная карта',
    back: 'Назад',
    nextStep: 'Дальше',
    prevStep: 'Назад',
    loadMore: 'Загрузить ещё',
    delete: 'Удалить',
    change: 'Изменить',
    selectFile: 'Выберите файл',
    select: 'Выбрать',
    orDragFile: 'или перетащите файл',
    profile: {
      settings: 'Настройка профиля',
      cards: 'Управление картами',
      exit: 'Выйти',
    },
    showAll: 'Посмотреть все',
    rollUp: 'Свернуть',
    changePassword: 'Изменить пароль',
    yes: 'Да',
    cancel: 'Отмена',
    add: 'Добавить',
    understand: 'Понятно',
    close: 'Закрыть',
    selectDate: 'Выбрать дату',
    goTelegram: 'Перейти в телеграм',
  },
  titles: {
    gender: 'Пол',
    preferredLanguage: 'На каком языке к вам обращаться?',
    sendCode: 'Код был выслан на номер {phone}',
    card: {
      title: 'Добавить новую карту',
    },
    sum: 'сум',
    hello: 'Добро пожаловать',
    status: {
      title: 'Статус рассрочки',
      ACTIVE: 'Активный',
      OVERDUE: 'Задерживается',
      PROBLEM: 'Проблемный',
      CLOSED: 'Закрытый',
      PAUSED: 'На паузе',
      DELETED: 'Удаленный',
      undefined: 'Неизвестно',
    },
    yes: 'Да',
    no: 'Нет',
  },
  reloadModal: {
    siteHasBeenUpdated: 'Были добавлены некоторые изменения',
    pleaseUpdate: 'Пожалуйста, обновитесь для корректной работы сайта',
    updateNow: 'Обновить сейчас',
    updateLater: 'Обновить позже',
  },
  errors: {
    unknown:
      'Нестабильное соединение, пожалуйста проверьте интернет. Если у вас остались вопросы, обращайтесь <a href="https://t.me/alifazo" target="_blank" class="text-red-400 underline font-bold"> сюда </a>',
    telegram: 'Что-то пошло не так, пожалуйста обратитесь к Alif A’zo в телеграм',
  },
  password: {
    maxLength: 'Не менее 8 символов',
    oneLowerCase: ' Как минимум одна заглавная и одна строчная буква',
    onlyLatin: 'Только латинские буквы',
    minOneNumber: 'Как минимум одна цифра',
    minOneSybol: 'Как минимум один дополнительный символ',
  },
  days: ['день', 'дня', 'дней'],
};
