<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="7.2" fill="#39A83E" />
    <g clip-path="url(#clip0_6040_18067)">
      <path
        d="M31.7051 24.1539C31.9268 24.8202 32.4777 25.2444 33.1258 25.2523C33.8824 25.2618 34.6406 25.2523 35.3972 25.2523C35.4769 25.2523 35.5567 25.257 35.6364 25.2523C35.7807 25.2421 35.8684 25.291 35.8588 25.4479C35.8429 25.7081 35.9656 26.0251 35.8078 26.2127C35.6499 26.4004 35.3294 26.2703 35.0863 26.275C34.462 26.2869 33.837 26.2593 33.2135 26.2821C31.8765 26.3326 30.7676 25.2034 30.5682 24.0845C30.3067 22.6195 31.5249 21.1772 32.7288 21.0558C32.9241 21.0281 33.1208 21.0102 33.318 21.0022C34.0355 21.0022 34.753 21.0022 35.4706 21.0022C35.8445 21.0022 35.8564 21.0156 35.8604 21.3689C35.8604 21.5266 35.8524 21.6843 35.8604 21.842C35.8692 21.9807 35.7887 22.0312 35.677 22.0501C35.5732 22.0664 35.4681 22.0741 35.3629 22.073C34.6188 22.073 33.8747 22.073 33.1306 22.073C32.4211 22.073 31.9953 22.4673 31.6956 23.0531C31.8104 23.169 31.9347 23.1241 32.0455 23.1241C33.1617 23.128 34.2779 23.1241 35.394 23.1241C35.4865 23.1241 35.5798 23.1288 35.6723 23.1241C35.811 23.1162 35.866 23.1856 35.8604 23.3133C35.8485 23.5736 35.9641 23.8905 35.8142 24.079C35.6643 24.2675 35.3406 24.1421 35.0966 24.1444C34.1009 24.1547 33.1035 24.1444 32.1085 24.1444L31.7051 24.1539Z"
        fill="white"
      />
      <path
        d="M17.5044 24.7706C17.5044 25.1775 17.5092 25.5844 17.5044 25.9913C17.5044 26.2523 17.4725 26.2838 17.2023 26.2775C16.9687 26.272 16.6753 26.3839 16.5174 26.216C16.3779 26.0685 16.4824 25.7902 16.4704 25.5694C16.4608 25.4014 16.5174 25.187 16.4337 25.075C16.3237 24.9299 16.0885 25.0198 15.9091 25.0182C15.1525 25.0103 14.3943 25.0182 13.6401 25.0182C13.2088 25.0182 13.2088 25.0182 13.2088 25.4637C13.2088 25.6735 13.2088 25.884 13.2088 26.0946C13.2088 26.2262 13.1554 26.2751 13.0215 26.287C12.1716 26.3658 12.1716 26.3658 12.1716 25.5308C12.1716 24.8999 12.1875 24.2691 12.1716 23.6383C12.1293 22.3909 13.2192 21.2491 14.3752 21.0496C15.7696 20.813 17.0356 21.7482 17.3753 22.8632C17.4603 23.1237 17.505 23.3954 17.5076 23.6691C17.5076 24.0365 17.5076 24.404 17.5076 24.773L17.5044 24.7706ZM14.8328 23.9884H16.0287C16.5023 23.9884 16.5453 23.9372 16.4552 23.4767C16.4401 23.3978 16.4178 23.3253 16.4002 23.2488C16.1961 22.353 15.1111 21.7372 14.1528 22.1922C13.5333 22.4863 13.2009 23.0004 13.2088 23.7006C13.2088 23.9821 13.2088 23.9861 13.4823 23.9876C13.9303 23.9908 14.3816 23.9884 14.8328 23.9884Z"
        fill="white"
      />
      <path
        d="M6.0333 23.6346C6.0333 22.8863 6.0333 22.1364 6.0333 21.3897C6.0349 21.0144 6.04207 21.0057 6.40323 21.0049C7.45241 21.0002 8.5008 20.9931 9.54999 21.0081C10.0313 21.0087 10.4928 21.1976 10.8338 21.5335C11.1748 21.8694 11.3676 22.3251 11.3701 22.8012C11.3674 23.2766 11.1761 23.7319 10.8373 24.0692C10.4986 24.4065 10.0394 24.5987 9.55876 24.6045C8.81572 24.6155 8.07188 24.6045 7.32645 24.6045C7.07133 24.6045 7.06814 24.61 7.06654 24.8647C7.06654 25.259 7.05857 25.6532 7.06654 26.0475C7.06654 26.2162 7.01233 26.2911 6.83853 26.2793C6.58819 26.262 6.28045 26.3747 6.10187 26.2336C5.90973 26.0806 6.03888 25.7652 6.0333 25.5239C6.02374 24.8955 6.0333 24.2654 6.0333 23.6346ZM8.37563 23.5857C8.72085 23.5857 9.06606 23.5786 9.41206 23.5857C9.55751 23.5897 9.70228 23.5648 9.8378 23.5124C10.2691 23.35 10.4644 22.8138 10.2253 22.444C10.0658 22.2003 9.84497 22.0379 9.54281 22.0347C8.77346 22.0276 8.00332 22.0347 7.23078 22.03C7.1096 22.03 7.07133 22.0804 7.07133 22.1932C7.07133 22.6001 7.07133 23.0077 7.07133 23.4146C7.07133 23.5581 7.13511 23.5897 7.26506 23.5881C7.6318 23.5818 8.00332 23.5857 8.37563 23.5857Z"
        fill="white"
      />
      <path
        d="M29.7215 24.6926C29.7215 25.1524 29.7215 25.6121 29.7215 26.0718C29.7215 26.22 29.6681 26.2886 29.5126 26.2823C29.3268 26.2752 29.1403 26.2823 28.9545 26.2823C28.7153 26.2744 28.6906 26.2468 28.6803 26.0024C28.6763 25.9235 28.6803 25.8447 28.6803 25.7658C28.6803 25.0435 28.6803 24.321 28.6803 23.5982C28.6737 23.2557 28.5547 22.9247 28.3411 22.6551C28.1275 22.3856 27.831 22.1921 27.4963 22.1039C27.1039 21.9996 26.6863 22.0431 26.3245 22.2261C25.9627 22.4091 25.6825 22.7186 25.5383 23.0943C25.4817 23.2691 25.4579 23.4527 25.4681 23.636C25.4625 24.3717 25.4681 25.1074 25.4681 25.8439C25.4681 25.9227 25.4681 26.0016 25.4681 26.0804C25.461 26.2003 25.4028 26.2799 25.2728 26.2815C25.0472 26.2815 24.8216 26.2815 24.5951 26.2815C24.5687 26.2834 24.5421 26.2798 24.5171 26.2708C24.4922 26.2619 24.4694 26.2479 24.4503 26.2296C24.4312 26.2114 24.4162 26.1894 24.4062 26.1651C24.3963 26.1407 24.3917 26.1146 24.3926 26.0883C24.3863 26.0229 24.3926 25.9566 24.3926 25.8912C24.3926 25.1815 24.3799 24.4719 24.3926 23.7622C24.4046 23.2236 24.4963 22.7001 24.8152 22.238C25.3334 21.4897 26.0334 21.0418 26.9582 21.0118C27.5986 20.9868 28.227 21.1893 28.7296 21.5827C29.2322 21.9761 29.5758 22.5344 29.6984 23.1566C29.72 23.2996 29.7272 23.4443 29.7199 23.5887C29.7199 23.957 29.7199 24.3244 29.7199 24.6926H29.7215Z"
        fill="white"
      />
      <path
        d="M20.3961 25.3652C20.3848 25.1687 20.3866 24.9717 20.4017 24.7754C20.4288 24.572 20.3092 24.5349 20.1625 24.4939C19.7049 24.367 19.3341 24.1067 19.0033 23.7732C18.5249 23.2922 18.3049 22.7087 18.2546 22.0527C18.2395 21.8037 18.2395 21.5541 18.2546 21.3051C18.2658 21.0481 18.3113 21.011 18.5616 21.0055C18.7338 21.0055 18.9068 20.9976 19.079 21.0055C19.2815 21.015 19.3254 21.0615 19.3309 21.2712C19.3381 21.5204 19.3461 21.7704 19.3309 22.0188C19.2847 22.8073 20.0668 23.5808 20.8999 23.5469C21.6685 23.5154 22.3183 23.0872 22.508 22.4004C22.5782 22.1433 22.5455 21.8792 22.5471 21.6174C22.5471 20.9432 22.4897 21.0047 23.1665 21.0039C23.2463 20.9991 23.3262 21.0007 23.4057 21.0087C23.4529 21.0115 23.4973 21.0321 23.5296 21.0663C23.5619 21.1005 23.5798 21.1456 23.5795 21.1924C23.566 21.8413 23.6688 22.499 23.3403 23.1156C23.0216 23.7406 22.4721 24.2205 21.8056 24.4561C21.4867 24.5728 21.4867 24.5712 21.4819 24.915C21.4771 25.2588 21.4819 25.6246 21.4819 25.9779C21.4819 26.2452 21.4492 26.272 21.1901 26.2791C21.0307 26.2839 20.8712 26.2831 20.7118 26.2791C20.4304 26.2728 20.408 26.2499 20.4032 25.955C20.3929 25.7587 20.3961 25.5624 20.3961 25.3652Z"
        fill="white"
      />
      <path
        d="M39.3508 21.0039C40.1337 21.0039 40.9166 21.0039 41.6987 21.0039C41.9881 21.0039 41.9905 21.0094 41.9968 21.2799C42.0008 21.4502 42.0016 21.6205 41.9968 21.7909C41.9889 22.0424 41.961 22.07 41.6851 22.0739C41.3662 22.0787 41.0473 22.0739 40.7284 22.0739C40.5163 22.0739 40.3035 22.0802 40.0906 22.0739C39.9312 22.0684 39.8817 22.1402 39.8897 22.2861C39.8977 22.4319 39.8897 22.5746 39.8897 22.7189C39.8897 23.7556 39.8897 24.792 39.8897 25.8281C39.8897 25.9069 39.8897 25.9858 39.8897 26.0646C39.8935 26.094 39.8906 26.1239 39.881 26.1519C39.8714 26.18 39.8555 26.2055 39.8344 26.2266C39.8133 26.2477 39.7877 26.2637 39.7594 26.2735C39.7311 26.2833 39.701 26.2865 39.6712 26.2831C39.4586 26.2831 39.246 26.2831 39.0334 26.2831C38.8844 26.2831 38.815 26.2105 38.8126 26.0678C38.8126 25.9763 38.8126 25.8849 38.8126 25.7926C38.8126 24.7037 38.8126 23.6147 38.8126 22.5265C38.8126 22.0006 38.878 22.0858 38.3414 22.0795C37.9037 22.0739 37.4644 22.0795 37.0283 22.0795C36.7397 22.0755 36.6664 22.0109 36.6664 21.7396C36.6664 20.8919 36.5771 21.015 37.3624 21.0102C38.0249 21 38.6874 21.0039 39.3508 21.0039Z"
        fill="white"
      />
      <path
        d="M16.8754 29.9591C16.8881 30.0506 16.8419 30.172 16.8036 30.2974C16.7733 30.3959 16.6976 30.4913 16.8251 30.6128C16.9527 30.7342 16.7701 30.8083 16.7087 30.8911C16.6577 30.9581 16.582 31.063 16.4855 30.9605C16.3699 30.8375 16.2599 30.8675 16.1347 30.9258C15.8621 31.0528 15.5878 30.9873 15.3247 30.9069C14.8934 30.7752 14.6207 30.3297 14.6335 29.8747C14.6431 29.5089 14.8814 29.0294 15.351 28.8954C15.8389 28.7566 16.287 28.8244 16.6418 29.2321C16.8156 29.4308 16.869 29.6626 16.8754 29.9591ZM16.2687 30.0458C16.3396 29.6689 16.1778 29.3709 15.8565 29.2889C15.6085 29.2258 15.2785 29.4576 15.2083 29.743C15.188 29.8256 15.1844 29.9113 15.1978 29.9952C15.2112 30.0792 15.2413 30.1596 15.2864 30.232C15.3315 30.3043 15.3907 30.367 15.4605 30.4166C15.5303 30.4661 15.6093 30.5014 15.693 30.5205C15.7568 30.5347 15.8525 30.5655 15.8939 30.4598C15.8557 30.3455 15.6875 30.3352 15.6803 30.198C15.937 29.8542 15.937 29.8542 16.2687 30.0458Z"
        fill="white"
      />
      <path
        d="M28.7536 28.9088C29.2965 28.8583 29.2957 28.8583 29.2965 29.3282C29.2965 29.5908 29.2902 29.8542 29.3021 30.1168C29.3117 30.3249 29.4233 30.4472 29.5987 30.4763C29.8682 30.5213 30.0268 30.4038 30.0412 30.1168C30.0563 29.7887 30.0412 29.4599 30.0499 29.1319C30.0499 29.0483 30.0013 28.9229 30.1217 28.8954C30.2613 28.8524 30.4116 28.8583 30.5474 28.9119C30.616 28.945 30.5873 29.0302 30.5881 29.0925C30.5881 29.4213 30.5809 29.7501 30.5881 30.0781C30.6104 30.8241 30.0404 31.052 29.452 30.9755C29.0191 30.9195 28.7672 30.5946 28.752 30.1538C28.748 30.0355 28.752 29.9173 28.752 29.799L28.7536 28.9088Z"
        fill="white"
      />
      <path
        d="M19.0902 29.6539C19.0734 29.8345 19.1181 30.0569 19.0646 30.2769C18.9522 30.7374 18.7952 30.925 18.2084 30.9865C17.6216 31.048 17.2461 30.6987 17.215 30.1231C17.1951 29.7698 17.215 29.4134 17.2094 29.0618C17.2094 28.9253 17.2573 28.8694 17.392 28.8772C17.4581 28.8808 17.5244 28.8808 17.5905 28.8772C17.7085 28.8701 17.7548 28.9253 17.754 29.0397C17.754 29.3409 17.754 29.6421 17.754 29.9433C17.7473 30.0607 17.7677 30.178 17.8138 30.2863C17.8409 30.3536 17.8877 30.4112 17.9482 30.452C18.0088 30.4927 18.0802 30.5147 18.1534 30.515C18.2266 30.5154 18.2982 30.4935 18.3582 30.4521C18.4183 30.4108 18.464 30.352 18.489 30.284C18.5341 30.1756 18.5547 30.0588 18.5496 29.9417C18.5496 29.6531 18.5568 29.3645 18.5496 29.0744C18.5448 28.9167 18.5975 28.856 18.7545 28.8757C18.8206 28.8792 18.8869 28.8792 18.953 28.8757C18.9726 28.873 18.9925 28.8749 19.0111 28.8813C19.0298 28.8877 19.0466 28.8984 19.0603 28.9125C19.074 28.9266 19.0841 28.9436 19.0899 28.9623C19.0956 28.981 19.0968 29.0007 19.0933 29.02C19.0878 29.2202 19.0902 29.4174 19.0902 29.6539Z"
        fill="white"
      />
      <path
        d="M23.428 30.8652C23.3076 30.9606 23.1968 30.9157 23.0948 30.9315C23.0628 30.9388 23.0297 30.9398 22.9974 30.9342C22.9652 30.9286 22.9343 30.9166 22.9069 30.8989C22.8794 30.8812 22.8558 30.8582 22.8375 30.8313C22.8193 30.8044 22.8067 30.7741 22.8006 30.7422C22.7456 30.5593 22.6323 30.4915 22.4442 30.5135C22.3381 30.5227 22.2314 30.5227 22.1253 30.5135C21.9937 30.5072 21.8861 30.5293 21.855 30.6791C21.8048 30.9047 21.6485 30.9606 21.4373 30.9291C21.3558 30.9241 21.274 30.9241 21.1925 30.9291C21.3878 30.456 21.5696 30.0215 21.7506 29.5886C21.8303 29.3962 21.9053 29.1999 22.0001 29.0146C22.095 28.8293 22.5263 28.8103 22.6044 28.9822C22.8907 29.6052 23.1577 30.2407 23.428 30.8652ZM22.2879 29.6351C22.2178 29.7786 22.1357 29.8796 22.146 30.0207C22.2584 30.083 22.3724 30.0641 22.5112 30.0373C22.4498 29.9103 22.4474 29.7613 22.2879 29.6351Z"
        fill="white"
      />
      <path
        d="M26.7772 29.8804C26.7772 29.6178 26.7828 29.3544 26.7772 29.0919C26.7724 28.9491 26.8027 28.8695 26.9701 28.875C27.2492 28.8853 27.5282 28.8703 27.8064 28.8829C27.9798 28.8872 28.1458 28.9534 28.2736 29.0694C28.4014 29.1854 28.4824 29.3432 28.5017 29.5137C28.5198 29.6831 28.4782 29.8535 28.3839 29.996C28.2896 30.1386 28.1484 30.2445 27.9842 30.2959C27.8341 30.3476 27.6748 30.3685 27.5163 30.3574C27.3871 30.3488 27.313 30.3906 27.3225 30.5309C27.3257 30.5834 27.3257 30.6361 27.3225 30.6886C27.3122 30.9252 27.3122 30.9252 27.0833 30.9299C26.7844 30.9299 26.7828 30.9299 26.7812 30.6279C26.7812 30.3779 26.7812 30.1288 26.7812 29.8788L26.7772 29.8804ZM27.3193 29.5902C27.3193 29.8993 27.3193 29.9056 27.5354 29.9056C27.7881 29.9056 27.9619 29.7779 27.9484 29.6013C27.9348 29.4246 27.7227 29.2858 27.4788 29.2929C27.2348 29.3 27.356 29.4916 27.3193 29.5902Z"
        fill="white"
      />
      <path
        d="M24.5569 29.5223C24.7028 29.4127 24.7786 29.2439 24.8575 29.0847C24.9364 28.9254 25.0504 28.8481 25.233 28.8773C25.3358 28.8938 25.4451 28.8536 25.5615 28.9175C25.4499 29.2392 25.2258 29.501 25.0433 29.777C24.8839 30.0015 24.8083 30.2738 24.8296 30.5474C24.8344 30.6262 24.8296 30.7051 24.8296 30.7839C24.8296 30.8833 24.7841 30.9266 24.6885 30.9274H24.4166C24.4004 30.9296 24.3839 30.928 24.3684 30.9229C24.3529 30.9178 24.3387 30.9093 24.327 30.8979C24.3153 30.8866 24.3064 30.8728 24.301 30.8575C24.2955 30.8423 24.2936 30.826 24.2954 30.8099C24.2894 30.7183 24.2894 30.6264 24.2954 30.5347C24.3297 30.258 24.2308 30.0269 24.0786 29.803C23.8824 29.5136 23.7023 29.214 23.5101 28.912C23.9406 28.7929 24.2277 28.9017 24.3871 29.3062C24.4086 29.3819 24.4381 29.4836 24.5569 29.5223Z"
        fill="white"
      />
      <path
        d="M31.6077 29.6651C31.6077 29.8623 31.6165 30.0594 31.6077 30.2557C31.5974 30.4347 31.6779 30.4828 31.8429 30.475C32.0422 30.4647 32.2416 30.475 32.4409 30.475C32.6402 30.475 32.5788 30.6256 32.5884 30.7312C32.5979 30.8369 32.5884 30.9362 32.4289 30.9339C32.0303 30.9276 31.6317 30.9291 31.233 30.9339C31.115 30.9339 31.0688 30.8881 31.0736 30.7714C31.0736 30.1932 31.0736 29.6149 31.0736 29.0367C31.0736 28.9153 31.1326 28.879 31.245 28.879C31.2981 28.8825 31.3513 28.8825 31.4044 28.879C31.5471 28.8687 31.6293 28.9066 31.6181 29.0745C31.6045 29.2709 31.6181 29.4688 31.6181 29.6659L31.6077 29.6651Z"
        fill="white"
      />
      <path
        d="M19.5238 28.9301C19.5804 28.8622 19.637 28.8804 19.6873 28.8804C20.097 28.8804 20.0452 28.8536 20.0468 29.236C20.0468 29.5893 20.0516 29.9457 20.0468 30.2958C20.0468 30.433 20.105 30.4771 20.2358 30.474C20.447 30.4685 20.6591 30.474 20.8736 30.474C21.0697 30.474 21.0227 30.6135 21.033 30.7216C21.0434 30.8296 21.0442 30.9329 20.8807 30.9305C20.471 30.9258 20.0604 30.9305 19.6506 30.9305C19.5469 30.9305 19.5143 30.8785 19.5143 30.7854C19.5143 30.2619 19.5143 29.7391 19.5143 29.2155C19.5087 29.1138 19.48 29.0073 19.5238 28.9301Z"
        fill="white"
      />
      <path
        d="M33.4526 29.2676C33.4279 29.5231 33.4 29.8196 33.3681 30.1169C33.367 30.1397 33.3611 30.1621 33.3508 30.1825C33.3406 30.203 33.3262 30.2212 33.3086 30.236C33.291 30.2508 33.2704 30.2619 33.2483 30.2685C33.2262 30.2751 33.2029 30.2772 33.18 30.2746C33.0771 30.2746 32.995 30.2296 32.9894 30.1169C32.9695 29.7541 32.8786 29.3977 32.8914 29.0311C32.8953 28.9136 32.9424 28.8663 33.0564 28.8734C33.0699 28.8734 33.0835 28.8734 33.0963 28.8734C33.4686 28.8608 33.4686 28.8608 33.4526 29.2676Z"
        fill="white"
      />
      <path
        d="M33.1649 30.9298C32.8978 30.9298 32.8978 30.9298 32.9002 30.6775C32.9002 30.4504 32.9002 30.4504 33.1497 30.448C33.4591 30.448 33.4591 30.448 33.4559 30.7003C33.4535 30.929 33.4535 30.929 33.1649 30.9298Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6040_18067">
        <rect width="36" height="10" fill="white" transform="translate(6 21)" />
      </clipPath>
    </defs>
  </svg>
</template>
