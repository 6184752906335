import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { setApiLocaleHeader } from '~/services/apiClient';
import { getApp } from '~/services/app.api';
import { getCreditsApp } from '~/services/credits.api';

import { checkAppLocaleExist, setApp } from '~/shared/at-app';
import { globalState } from '~/shared/globalState';

const isLocaleChanging = ref(false);

export const useGlobals = () => {
  const { locale: currentLocale } = useI18n({ useScope: 'global' });
  const getApplication = async (locale = currentLocale.value, isDefault = false) => {
    return Promise.all([getApp(), getCreditsApp()]).then(([app, creditsApp]) => {
      setApp({ ...app.data, ...creditsApp.data }, locale, isDefault);
    });
  };
  const updateAppLocale = async locale => {
    if (!checkAppLocaleExist(locale)) {
      isLocaleChanging.value = true;
      await getApplication(locale, false);
      isLocaleChanging.value = false;
    } else {
      return Promise.resolve();
    }
  };
  const $cookies = inject('$cookies');
  const changeLocale = async (lang, prevLang, isPublic) => {
    setApiLocaleHeader(lang);
    if (!isPublic) {
      await updateAppLocale(lang, prevLang);
    }
    $cookies.set('lang', lang, '1Y');
    currentLocale.value = lang;
    globalState.locale = lang;
  };
  return {
    isLocaleChanging,
    getApplication,
    changeLocale,
  };
};
