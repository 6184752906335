const config = {
  env: 'production',
  inFrame: true,
};

function loadScript(callback, token) {
  if (typeof document === 'undefined') {
    return;
  }
  var head = document.head || document.getElementsByTagName('head')[0];
  const script = document.createElement('script');

  script.async = true;
  const src = '//code-ya.jivosite.com/widget/';
  script.src = src + token;

  head.appendChild(script);

  script.onload = callback;
}

export default function install(Vue, options = {}) {
  const _options = { ...config, ...options };
  if (_options.env === 'production' && (_options.inFrame ? true : window.location === window.parent.location))
    loadScript(() => {}, options.token);
  else console.warn('[vue-jivochat] Tracking is disabled, because env option is not "production" or is in iframe');
}
