<template>
  <div class="container mx-auto w-full px-4 sm:px-6 lg:px-8" :class="{ 'container_short': short }">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'AtContainer',
    props: {
      short: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<!--:class="short ? 'max-w-5xl' : 'max-w-7xl'"-->
<style lang="scss" scoped>
  .container {
    max-width: calc(1280px + 32px);

    @screen sm {
      max-width: calc(1280px + 48px);
    }

    @screen lg {
      max-width: calc(1280px + 64px);
    }

    &_short {
      max-width: calc(1024px + 32px);

      @screen sm {
        max-width: calc(1024px + 48px);
      }

      @screen lg {
        max-width: calc(1024px + 64px);
      }
    }
  }
</style>
