// import app from "../main";
// import router from "~/router";
import { apiClient } from '~/services/apiClient';

import { formatMoney } from '~/utils';

const END_POINT = '/credits';

/**
 *
 * @returns
 */
const getCreditsApp = () => apiClient.get('/credits/app');

/**
 *
 * @returns
 */
const getCredits = (params = {}) =>
  apiClient.get(`${END_POINT}`, { params }).then(response => {
    const credits = response.data?.data ?? [];
    const template = credits.map(credit => {
      if (credit?.meta) {
        const meta = credit.meta;
        const _meta = {
          _balance: formatMoney(Number(((meta?.balance ?? 0) / 100).toFixed(2))),
        };
        Object.assign(meta, _meta);
      }

      if (credit?.next_payment) {
        const next_payment = credit.next_payment;
        const _next_payment = {
          _payment_amount: formatMoney(Number(((next_payment?.payment_amount ?? 0) / 100).toFixed(2))),
        };
        Object.assign(next_payment, _next_payment);
      }

      return credit;
    });

    response.data.data = [...template];
    return response;
  });

/**
 *
 * @param {*} credit_id
 * @returns
 */
const getCredit = credit_id =>
  apiClient.get(`${END_POINT}/${credit_id}`).then(response => {
    const credit = response.data;
    const firstUnpaidPayment = credit.schedule_cup_payments.find(payment => !payment.paid);
    const _schedule_cup_payments = credit.schedule_cup_payments.map(payment => {
      const _paymentAmount = Number(
        ((payment.paid ? payment?.payment_amount : payment?.payment_balance || 0) / 100).toFixed(2)
      );

      return {
        ...payment,
        _payment_number: Number(payment.payment_number) - 1,
        _payment_amount: _paymentAmount,
        _formatted_payment_amount: formatMoney(_paymentAmount),
        _payment_date: payment.paid ? payment.fully_paid_date : payment.payment_date,
        _canPay: firstUnpaidPayment?.payment_number === payment?.payment_number,
      };
    });
    const distanceInMonth = credit.schedule_cup_payments.reduce(
      (acc, payment) => (acc += payment.payment_type === 'monthly' ? 1 : 0),
      0
    );
    const applicationAmount = credit.info_credit.application_items.reduce((acc, item) => {
      return acc + item.price;
    }, 0);
    credit._formatted_application_amount = credit._total_amount = formatMoney(
      Number(((applicationAmount || 0) / 100).toFixed(2)),
      0
    );
    credit._application_amount = credit._total_amount = formatMoney(
      Number(((credit?.total_amount || 0) / 100).toFixed(2)),
      0
    );
    credit._remainder = formatMoney(Number(((credit?.monthly_payment_amount || 0) / 100).toFixed(2)), 0);
    credit._penalty = formatMoney(Number(((credit?.credit_penalty?.approved_amount || 0) / 100).toFixed(2)), 0);
    credit._formatted_total_paid_amount = formatMoney(Number(((credit?.total_paid_amount || 0) / 100).toFixed(2)), 0);
    credit._distanceInMonth = distanceInMonth;
    credit.schedule_cup_payments = _schedule_cup_payments;
    return response;
  });

/**
 *
 * @param {*} body
 * @returns
 */
const calculateCredits = (body = {}) =>
  apiClient.post(`${END_POINT}/calculate-credits`, body).then(response => {
    const data = response.data || {};
    const template = {
      ...data,
      _monthly_payment_amount: formatMoney(Number(((data.monthly_payment_amount || 0) / 100).toFixed(2)), 0),
      _total_amount: formatMoney(Number(((data?.total_amount || 0) / 100).toFixed(2)), 0),
    };
    response.data = { ...template };
    return response;
  });

/**
 *
 * @param {*} credit_id
 * @param {*} params
 * @returns
 */
const getDelays = (credit_id, params = {}) =>
  apiClient.get(`${END_POINT}/${credit_id}/delays`, params).then(response => {
    response.data.data = response.data.data.map(delay => {
      return {
        ...delay,
        _payment_date: delay?.payment_date,
      };
    });
    return response;
  });

/**
 *
 * @param {*} id - credit id
 * @param {*} amount
 * @returns
 */
const payCredit = (id, amount) =>
  apiClient.post(`${END_POINT}/${id}/withdraw`, {
    amount,
  });

/**
 *
 * @param {*} creditId
 * @returns
 */
const getCreditPenalty = creditId =>
  apiClient.get(`${END_POINT}/${creditId}/penalty-days?paginate=0`).then(response => {
    response.data = response.data.map(penalty => {
      return {
        ...penalty,
        _formatted_amount: formatMoney(Number(Math.ceil((penalty?.amount || 0) / 100).toFixed(2)), 0),
      };
    });
    return response;
  });

/**
 *
 * @param {*} creditId
 * @param {*} payload
 * @returns
 */
const requestDelay = (creditId, payload) => apiClient.post(`${END_POINT}/${creditId}/request-delay`, payload);

/**
 *
 * @param {*} id
 * @returns
 */
const getCreditItems = id =>
  apiClient.get(`${END_POINT}/reject-cases/credit-items/${id}`).then(res => {
    const data = res.data;
    res.data = data.map(item => ({
      ...item,
      _price: formatMoney(Number(((item.price ?? 0) / 100).toFixed(2)), 0),
    }));
    return res;
  });

/**
 *
 * @param {*} body
 * @returns
 */

//  { headers: { 'Content-Type': 'multipart/form-data' } }
const createRejectCase = body => apiClient.post(`${END_POINT}/reject-cases/store`, body);

/**
 *
 * @returns
 */
const getRejectCases = () => apiClient.get(`${END_POINT}/reject-cases/list`);

const getCertificateOfClosure = id =>
  apiClient.get(`${END_POINT}/${id}/generate-letter-closed-credit`, { responseType: 'blob' }).catch(() => {});

const requestQueue = {};
const getBnplContract = creditId => {
  const link = `/credits/${creditId}/bnpl-contract`;
  if (link in requestQueue) {
    return requestQueue[link];
  }
  return (requestQueue[link] = apiClient
    .post(
      link,
      {},
      {
        responseType: 'blob',
      }
    )
    .finally(() => {
      delete requestQueue[link];
    }));
};
const getBmlContract = creditId => {
  const link = `/credits/${creditId}/bml-contract`;
  if (link in requestQueue) {
    return requestQueue[link];
  }
  return (requestQueue[link] = apiClient
    .post(
      link,
      {},
      {
        responseType: 'blob',
      }
    )
    .finally(() => {
      delete requestQueue[link];
    }));
};

export {
  getBnplContract,
  getBmlContract,
  getCreditsApp,
  getCredits,
  getCredit,
  calculateCredits,
  getDelays,
  payCredit,
  getCreditPenalty,
  requestDelay,
  getCreditItems,
  createRejectCase,
  getRejectCases,
  getCertificateOfClosure,
};
