<template>
  <template v-if="isInnerApplicationInit">
    <router-view />
  </template>
</template>

<script>
  export default {
    name: 'InnerLayout',
  };
</script>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { getToken } from '~/services/apiClient';
  import { globalState } from '~/shared/globalState';
  import { useGlobals } from '~/hooks/useGlobals';

  const hasAuthToken = Boolean(getToken());

  const isInnerApplicationInit = ref(false);

  const { getApplication } = useGlobals();
  const { locale } = useI18n({ useScope: 'global' });

  const initApplication = async () => {
    if (hasAuthToken) {
      globalState.isLoading = true;

      await getApplication(locale.value, true);

      globalState.isLoading = false;
    }
    isInnerApplicationInit.value = true;
  };
  initApplication();
</script>

<style scoped></style>
