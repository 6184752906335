export const COOKIE_AUTH_TOKEN = 'auth';
export const AUTH_COMPONENT_NAME = 'Auth.Login';
export const PASSPORT_FIELDS = [
  'PASSPORT',
  // "PASSPORT_FACE",
  'ADDRESS',
  'MILITARY_CERTIFICATE',
];
export const ID_CARD_FIELDS = ['ID_PASSPORT_FRONT', 'ID_PASSPORT_BACK', 'ID_PASSPORT_FACE', 'MILITARY_CERTIFICATE'];

export const MYID_CODES = {
  updateStatus: 'FE_UPDATE_IDENTIFICATION_STATUS',
  passportDataIncorrectly: 'PASSPORT_DATA_ENTERED_INCORRECTLY',
};

export const MYID_STATUSES = {
  PENDING: 'pending',
  NOT_IDENTIFIED: 'not_identified',
  IDENTIFICATED: 'identificated',
};

export const MONTHS = [
  { title_uz: 'Yanvar', title_ru: 'Январь', value: '01' },
  { title_uz: 'Fevral', title_ru: 'Февраль', value: '02' },
  { title_uz: 'Mart', title_ru: 'Март', value: '03' },
  { title_uz: 'Aprel', title_ru: 'Апрель', value: '04' },
  { title_uz: 'May', title_ru: 'Май', value: '05' },
  { title_uz: 'Iyun', title_ru: 'Июнь', value: '06' },
  { title_uz: 'Iyul', title_ru: 'Июль', value: '07' },
  { title_uz: 'Avgust', title_ru: 'Август', value: '08' },
  { title_uz: 'Sentyabr', title_ru: 'Сентябрь', value: '09' },
  { title_uz: 'Oktyabr', title_ru: 'Октябрь', value: '10' },
  { title_uz: 'Noyabr', title_ru: 'Ноябрь', value: '11' },
  { title_uz: 'Dekabr', title_ru: 'Декабрь', value: '12' },
];

export const CREDIT_STATUSES = {
  closed: 'CLOSED',
  active: 'ACTIVE',
  problem: 'PROBLEM',
  overdue: 'OVERDUE',
  completed: 'COMPLETED',
  deleted: 'DELETED',
};

export const SETTINGS_VALUES = {
  on: 'on',
  off: 'off',
};

export const CLIENT_STATUSES = {
  fixed: 'FIXED',
  reviewed: 'REVIEWED',
  identified: 'IDENTIFIED',
  created: 'CREATED',
};
