export default {
  titles: {
    help: 'Yordam',
    socials: 'Biz ijtimoiy tarmoqlarda',
    linkToPartners: 'hamkorlarimiz',
    advice: `Sizga berilgan limitni {linkToPartners} do'konida sarflashingiz mumkin`,
  },
  content: {
    contacts: ['24 soatlik<br />Call-markaz raqami', '24 soatlik<br />Call-markaz raqami'],
    address: "O'zbekiston, Toshkent, Shahriobod tumani, Shota Rustaveli ko'chasi, 12A uy",
  },
  links: {
    questions: 'Ko’p uchraydigan savollar',
    limits: "Cheklov haqida ko'proq ma'lumot",
    publicOffer: 'Ommaviy oferta',
  },
};
