import { createApp } from 'vue';
import VueCookie from 'vue-cookie';
import VueCookies from 'vue-cookies';

import AlifUI from '~/plugins/aliftech-ui';

import AtToast from '~/plugins/aliftech-ui/toast';

import { COOKIE_AUTH_TOKEN } from '~/constants';

import VueGTM from '~/plugins/gtm';
import i18n from '~/plugins/i18n';
import VueJivoChat from '~/plugins/jivochat';
import sentry from '~/plugins/sentry';
import VueYandexMetrika from '~/plugins/ya-metrika';

import { setToken } from '~/services/apiClient';
import { setApiLocaleHeader } from '~/services/apiClient';

import App from './App.vue';
import './assets/scss/fonts.scss';
import './assets/scss/main.scss';
import router from './router';
import { globalRouter } from './router/globalRouter';
import { globalState } from './shared/globalState';
import store from './store';

globalRouter.router = router;

const token = VueCookie.get(COOKIE_AUTH_TOKEN);
if (token) {
  try {
    setToken(token);
  } catch (e) {
    setToken('');
  }
}

const app = createApp(App);
const currentLang = VueCookies.get('lang') || i18n.global.locale.value;
if (currentLang !== i18n.global.locale.value) {
  i18n.global.locale.value = currentLang;
  globalState.locale = currentLang;
}
setApiLocaleHeader(i18n.global.locale.value);

app.use(sentry);
app.use(store);
app.use(router);
app.use(AlifUI);
app.use(AtToast);
app.use(i18n);
app.use(VueCookies);
app.use(VueCookies, {
  secure: true,
  sameSite: 'None',
});
app.use(VueJivoChat, {
  env: import.meta.env.VITE_ENVIRONMENT,
  inFrame: false,
  token: 'dYkpdzVkI1',
});
app.use(VueYandexMetrika, {
  id: 90501786,
  router: router,
  env: import.meta.env.VITE_ENVIRONMENT,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
  // other options
});
app.use(VueGTM);

// app.config.globalProperties.$_at_user = null;
app.provide('$toast', AtToast);
app.provide('inIframe', window?.self !== window?.top || window?.location !== window?.parent?.location || false);
app.provide('merchantUrl', import.meta.env.VITE_MERCHANT_URL);

app.mount('#app');

export default app;
