// import app from "../main";
// import router from "~/router";
import { apiClient } from '~/services/apiClient';

/**
 *
 * @returns Object
 */
const getShops = params => apiClient.get('/public/merchants', { params });

/**
 *
 * @param {*} id
 * @returns
 */
const getShop = id => apiClient.get(`/public/merchants/${id}`);
/**
 *
 * @param {*} params
 * @returns
 */
const getTags = (params = {}) => apiClient.get('/public/merchants/tags', { params });

/**
 *
 * @param {*} params
 * @returns
 */
const getStores = params => apiClient.get('/public/stores', { params });

/**
 *
 * @param {*} params
 * @returns
 */
const getConditions = params => apiClient.get('/public/conditions', { params });

/**
 *
 * @param {*} params
 * @returns
 */
const getRegions = params => apiClient.get('/public/regions', { params });

/**
 *
 * @param {*} params
 * @returns
 */
const getDistricts = params => apiClient.get('/public/districts', { params });

const getBanners = params => apiClient.get('/public/banners', { params });

/**
 *
 * @returns
 */
const checkPhone = params => apiClient.get(`/profile/check-phone`, { params });

const getNearestStores = params => apiClient.get('/public/nearest-stores', { params });

export {
  getShops,
  getShop,
  getTags,
  getStores,
  getConditions,
  getRegions,
  getDistricts,
  getBanners,
  checkPhone,
  getNearestStores,
};
