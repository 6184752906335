<template>
  <div class="faq">
    <div class="space-y-8 lg:space-y-12">
      <div class="space-y-5">
        <div class="text-2xl leading-8 font-bold lg:text-4xl lg:leading-10 lg:text-center">
          {{ $t('juma.faq.title') }}
        </div>
      </div>
      <div class="space-y-2 flex flex-col items-center">
        <div
          v-for="(item, index) in faqs"
          :key="index"
          class="faq__item w-full space-y-2 rounded-3xl text-left hover:bg-gray-100 overflow-hidden"
          :class="{
            'bg-gray-100': item.isOpen,
          }"
        >
          <button
            class="w-full space-x-2 flex items-center justify-between space-x-2 p-4 lg:p-6"
            :class="{
              'bg-gray-100 pb-0 lg:pb-0': item.isOpen,
            }"
            @click="item.isOpen = !item.isOpen"
          >
            <span class="text-base leading-6 font-medium text-left lg:text-xl">{{ $t(item.questionLocaleKey) }}</span>
            <ChevronDownIcon
              class="h-6 w-6 text-gray-900"
              :class="{
                'transform rotate-180': item.isOpen,
              }"
              style="min-width: 24px"
            />
          </button>
          <span v-if="item.isOpen" class="text-sm leading-5 block text-gray-500 lg:text-lg p-4 pt-0 lg:p-6 lg:pt-0">
            {{ $t(item.answerLocaleKey) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseFAQ',
  };
</script>

<script setup>
  import { ChevronDownIcon } from '@heroicons/vue/20/solid';
  import { ref, onBeforeMount } from 'vue';

  import { hasOwnProperty } from '~/plugins/aliftech-ui/utils/hasOwnProperty';

  const props = defineProps({
    items: {
      type: Array,
      default: () => [],
      required: true,
      validator(value) {
        return value.every(
          item => hasOwnProperty(item, 'questionLocaleKey') && hasOwnProperty(item, 'answerLocaleKey')
        );
      },
    },
  });

  const faqs = ref([]);

  onBeforeMount(() => {
    faqs.value = props.items.map(({ questionLocaleKey, answerLocaleKey }) => ({
      questionLocaleKey,
      answerLocaleKey,
      isOpen: false,
    }));
  });
</script>

<style lang="scss">
  .faq {
    &__item {
      max-width: 628px;
    }
  }
</style>
