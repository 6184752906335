import {
  createElementBlock as _createElementBlock,
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '32',
        height: '32',
        viewBox: '0 0 32 32',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          'fill-rule': 'evenodd',
          'clip-rule': 'evenodd',
          d: 'M9.89635 26.4271C10.459 26.4271 10.9164 26.8844 10.9164 27.4471C10.9164 28.0097 10.459 28.4657 9.89635 28.4657C9.33369 28.4657 8.87769 28.0097 8.87769 27.4471C8.87769 26.8844 9.33369 26.4271 9.89635 26.4271Z',
          stroke: '#10B981',
          'stroke-width': '2.5',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
        _createElementVNode('path', {
          'fill-rule': 'evenodd',
          'clip-rule': 'evenodd',
          d: 'M24.8996 26.4271C25.4622 26.4271 25.9196 26.8844 25.9196 27.4471C25.9196 28.0097 25.4622 28.4657 24.8996 28.4657C24.3369 28.4657 23.8796 28.0097 23.8796 27.4471C23.8796 26.8844 24.3369 26.4271 24.8996 26.4271Z',
          stroke: '#10B981',
          'stroke-width': '2.5',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
        _createElementVNode('path', {
          d: 'M3.6665 4.33334L6.43984 4.81334L7.72384 20.1107C7.82784 21.3573 8.86917 22.3147 10.1198 22.3147H24.6692C25.8638 22.3147 26.8772 21.4373 27.0492 20.2533L28.3145 11.5093C28.4705 10.4307 27.6345 9.46534 26.5452 9.46534H6.88517',
          stroke: '#1F2937',
          'stroke-width': '2.5',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
        _createElementVNode('path', {
          d: 'M18.8339 14.3934H22.5312',
          stroke: '#10B981',
          'stroke-width': '3',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
      ]
    )
  );
}
