import VueCookies from 'vue-cookies';

import * as Sentry from '@sentry/vue';

import { checkType } from '~/plugins/aliftech-ui/utils';

import { globalRouter } from '~/router/globalRouter';

import { apiClient, clearCookie, setToken } from '~/services/apiClient';

import { clearApp } from '~/shared/at-app';
import { $_at_user } from '~/shared/at-user';
import { $_clientRequirements } from '~/shared/client-requirements';
import { setStepsState } from '~/shared/registration-steps';

import { AUTH_COMPONENT_NAME, COOKIE_AUTH_TOKEN } from '../constants';

const END_POINT = '/auth';
const DEFAULT_EXPIRES_IN = 1;
const DEFAULT_REMEMBER_ME = '1D';

const setAuth = ({ access_token, token_type, expires_in }, rememberMe) => {
  const accessToken = access_token;
  const tokenType = token_type;
  const expiresIn = expires_in ?? DEFAULT_EXPIRES_IN;
  const token = tokenType + ' ' + accessToken;

  if (checkType(accessToken, 'string') && checkType(tokenType, 'string')) {
    const $cookie = VueCookies;
    if ($cookie) {
      clearCookie().finally(() => {
        $cookie.set(COOKIE_AUTH_TOKEN, token, {
          expires: rememberMe ? `${expiresIn}m` : DEFAULT_REMEMBER_ME,
        });
      });
      setToken(token);
    }
  }
};

/**
 *
 * @returns Object
 */
const getUser = () => apiClient.get(`${END_POINT}/user`);

/**
 * @param {Object} params - Request params
 * @returns {Promise<*>}
 *
 */
const loginByPassword = params =>
  apiClient
    .post(`${END_POINT}/login-password`, {
      remember_me: true,
      ...params,
    })
    .then(response => {
      setAuth(
        {
          access_token: response.data?.access_token,
          token_type: response.data?.token_type,
          expires_in: response.data?.expires_in,
        },
        params.remember_me
      );
      return response;
    });

const logout = () =>
  apiClient.post(`${END_POINT}/logout`).then(() => {
    clearCookie().finally(() => {
      delete apiClient.defaults.headers.common['Authorization'];
      globalRouter.router.push({ name: AUTH_COMPONENT_NAME }).then(() => {
        $_at_user.value = null;
        Sentry.setUser(null);
        clearApp();
        $_clientRequirements.value = null;
        setStepsState(null);

        sessionStorage.removeItem('offer_id');
        sessionStorage.removeItem('condition_id');
        sessionStorage.removeItem('source');
      });
    });
  });

const requestLoginBySms = params => apiClient.post(`${END_POINT}/request-login-by-sms`, params);

const loginBySms = params =>
  apiClient.post(`${END_POINT}/login-by-sms`, params).then(response => {
    setAuth({
      access_token: response.data?.access_token,
      token_type: response.data?.token_type,
      expires_in: response.data?.expires_in,
    });
    return response;
  });

/**
 *
 * @param {*} body
 * @returns
 */
const requestRegistration = body => apiClient.post(`${END_POINT}/request-registration`, body);
/**
 *
 * @param {*} body
 * @returns
 */
const confirmRegistration = body =>
  apiClient.post(`${END_POINT}/register`, body).then(response => {
    setAuth({
      access_token: response.data?.data?.authorization?.access_token,
      token_type: response.data?.data?.authorization?.token_type,
      expires_in: response.data?.data?.authorization?.expires_in,
    });
    return response;
  });

/**
 *
 * @param {*} body
 * @returns
 */
const requestIdentification = body => apiClient.post(`${END_POINT}/identification/request-identification`, body);

/**
 *
 * @param {*} body
 * @returns
 */
const checkIdentificationStatus = body =>
  apiClient.post(`${END_POINT}/identification/check-identification-status`, body);

/**
 *
 * @returns
 */
const getRequirements = () => apiClient.get(`/v2${END_POINT}/identification/requirements`);

/**
 *
 * @param {*} body
 * @returns
 */
const requestResetPassword = body => apiClient.post(`${END_POINT}/request-reset`, body);

/**
 *
 * @param {*} body
 * @returns
 */

/**
 *
 * @param {*} body
 * @returns
 */
const resetPassword = body => apiClient.post(`${END_POINT}/reset`, body);

export {
  setAuth,
  getUser,
  loginByPassword,
  logout,
  requestLoginBySms,
  loginBySms,
  requestIdentification,
  checkIdentificationStatus,
  getRequirements,
  requestResetPassword,
  resetPassword,
  requestRegistration,
  confirmRegistration,
};
