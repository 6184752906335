<template>
  <div v-bind="$attrs" class="keen-slider__slide">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'BaseCarouselSlide',
  };
</script>

<style scoped></style>
