import { apiClient } from '~/services/apiClient';

export const authRequestOtp = payload => apiClient.post('/auth/phone-verify', payload);

export const authConfirmOtp = payload => apiClient.post('/auth/confirm-otp', payload);

export const getApplicantInfo = () => apiClient.get('/registration/applicant-info');

export const myIdRequestIdentification = payload =>
  apiClient.post('/registration/my-id-request-identification', payload);

export const ocrRequestIdentificationByPassport = payload =>
  apiClient.post('/registration/ocr-recognition/passport', payload);

export const ocrRequestIdentificationByIdCard = payload =>
  apiClient.post('/registration/ocr-recognition/id-passport', payload);

export const loginByClientNotFound = () => apiClient.post('/registration/check-phone');

export const loginByDeviceNotFound = () => apiClient.post('/registration/check-device');

export const loginByExpiredPassport = () => apiClient.post('/registration/update-passport');
