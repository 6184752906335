export default {
  countdown: {
    label: 'aksiya boshlanishiga qoldi:',
    days: ['kun', 'kun', 'kun'],
    hours: ['soat', 'soat', 'soat'],
    minutes: 'daqiqa',
    seconds: 'soniya',
    untilStart: 'aksiyasigacha qoldi',
    untilEnd: 'aksiyasi tugashiga qoldi',
  },
  hero: {
    description: "muddatli to'lov ustamasi 2 barobargacha kamroq",
    button: "Muddatli to'lov uchun limit olish",
    promoInfo: "Aksiya 14 iyuldan 21 iyulgacha bo'lib o'tdi",
  },
  features: [
    "70 000 dan ortiq tovarlar Alif Shop saytida va hamkor-do'konlarda",
    "Muddatli to'lov komissiyasi 2 barobargacha kamaytirildi",
    "6 oydan 24 oygacha muddatli to'lov",
    "Butun O'zbekiston bo'ylab amal qiladi",
  ],
  partners: {
    title: 'Do’konlar ro’yxati',
    link: "Barcha hamkorlarni ko'rish",
  },
  categories: {
    title: 'Nimalar xarid qilish mumkin?',
    link: "Barcha tovarlarni ko'rish",
    items: [
      {
        title: 'Smartfonlar',
        price: "Oyiga 99 000 so'mdan",
      },
      {
        title: 'Konditsionerlar',
        price: "Oyiga 199 000 so'mdan",
      },
      {
        title: 'Muzlatgichlar',
        price: "Oyiga 119 000 so'mdan",
      },
      {
        title: 'Televizorlar',
        price: "Oyiga 99 000 so'mdan",
      },
      {
        title: 'Kir yuvish mashinalari',
        price: "Oyiga 159 000 so'mdan",
      },
      {
        title: 'Noutbuklar',
        price: "Oyiga 169 000 so'mdan",
      },
    ],
  },
  halal: {
    title: 'Alif Nasiya halolligi',
    description:
      "Alif Nasiya o'z xizmatlarini shariat me'yorlari va qonunlariga muvofiq amalga oshirishi, xizmatning ochiqligi va halolligini ko'rsatadi.",
    button: "Sertifikatni ko'rish",
  },
  family: {
    title: 'alif Juma oilaviy xarajatlarni yengillashtiradi',
    description:
      "Byudjetingizga ortiqcha yuklama qilmagan holda  tovarlarga oyma-oy to'lov qiling, va hayotingizni osonlashtiring",
    button: 'Xarid limitini bilish',
  },
  where: {
    title: 'Qayerdan xarid qilish mumkin?',
    items: [
      {
        title: 'Alif Shop orqali onlayn',
        points: ['Ostonagacha bepul yetkazib beramiz', '100 dan ortiq hamkorlar 1 joyda'],
        button: "Alif Shopga o'tish",
      },
      {
        title: "Hamkor-do'konlarda",
        points: ['Maslahatchi yordami', "O'zbekiston bo'ylab 2 000 dan ortiq hamkor do'konlarimiz"],
        button: "Do'konlar ro'yxati",
      },
    ],
  },
  reviews: {
    title: 'Mijozlarimiz fikrlari',
    items: [
      {
        name: 'Sanobar Kenjayeva',
        date: '15 iyun, 2023',
        text: "Assalomu Alaykum. Men Alif Shopdan 3ta telefon xarid qilganman. Juda qulayliklari ko'p. Hammaga tavsiya qilaman.",
      },
      {
        name: 'Gukish',
        date: '29 may, 2023',
        text: "Original tovarlar hammasi, bir biridan zo'r sifatigayam gap yo'q. Olgan odam ham sizdan rozi bo'ladi.",
      },
      {
        name: 'Gulchehra Inagamova',
        date: '11 may, 2023',
        text: "Klientlaringiz ko'paysin, biznesingizga omad va zafar tilayman.",
      },
      {
        name: 'Asadbek Ibrohimov',
        date: '19 aprel, 2023',
        text: 'Assalomu Alaykum. Sizlarni anchadan beri kuzataman. Hammaga javob yozib chiqasizlar erinmasdan. Omad.',
      },
      {
        name: 'Davron Kamaev',
        date: '5 aprel, 2023',
        text: "Tovarlaringizga gap yo'q, bir biridan zo'r.",
      },
    ],
  },
  mans: {
    title: "alif nasiyada ro'yxatdan o'ting va tovarlarni muddatli to'lovga xarid qiling",
    button: "Muddatli to'lovga limit olish",
  },
  faq: {
    title: "Tez-tez so'raladigan savollar",
    items: [
      {
        question: 'alif Juma bu nima?',
        answer:
          "alif Juma — bu tovarlarni imtiyozli shartlarda muddatli to'lovga sotib olish uchun noyob aksiya. Ushbu aksiya 14-iyuldan 21-iyulga qadar bo'lib o'tadi.",
      },
      {
        question: 'Limit bu nima?',
        answer:
          "Limit — ro'yxatdan o'tgandan keyin muddatli to'lovga mahsulot sotib olish uchun taqdim etiladigan balans. Uning yordamida siz tovarlarni ortiqcha kutmasdan alifshop.uz saytida yoki hamkorlarimiz do‘konlarida sotib olishingiz mumkin.",
      },
      {
        question: 'Limitni qanday tekshirish mumkin?',
        answer:
          "Limit olganingizdan so'ng, uni alifnasiya.uz saytidagi shaxsiy kabinetingizda yoki Alif ilovasidagi Nasiya bo‘limida tekshirishingiz mumkin.",
      },
      {
        question: 'Qayerdan xarid qilish mumkin?',
        answer:
          "Siz alifshop.uz saytida yoki IDEA, Mediapark, Elmakon, Goodzone va boshqalar kabi hamkorlarimiz do'konlarida xarid qilishingiz mumkin.",
      },
      {
        question: 'Mahsulot narxi menga berilgan limit miqdoridan oshsa nima qilish kerak?',
        answer:
          "Agar mahsulot narxi sizga berilgan limit miqdoridan oshgan bo'lsa, biz ushbu mahsulotga arizani bir necha daqiqa ichida ko'rib chiqib sizga javob beramiz.",
      },
      {
        question: "Oylik to'lovni qanday amalga oshirish mumkin?",
        answer:
          "Har oy bog'langan kartadan muddatli to'lovning belgilangan miqdori yechib olinadi. Va siz mustaqil ravishda alifnasiya.uz saytidagi shaxsiy kabinetingiz yoki Alif, Payme, Click va Paynet to‘lov tizimlari orqali to‘lovni amalga oshirishingiz mumkin.",
      },
    ],
  },
  mobi: {
    title: "alif Jumadan yanada ko'proq foyda olishni istaysizmi?",
    description:
      "Alif ilovasini yuklab oling, \"Nasiya\" bo'limida ro'yxatdan o'ting va har oy 1 000 000 so'm foizsiz o'tkazmalarga ega bo'ling",
  },
  contacts: {
    title: 'Yana savollar qoldimi?',
    description: "Biz bilan bog'laning",
  },
  promoBanner: {
    button: 'Batafsil',
  },
  conditions: {
    title: 'Eng foydali muddatli to’lov shartlari',
  },
};
