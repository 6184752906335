<template>
  <BaseFAQ :items="faqs" />
</template>

<script>
  export default {
    name: 'AppFAQ',
  };
</script>

<script setup>
  import { ref } from 'vue';

  import BaseFAQ from '~/components/base/BaseFAQ.vue';

  const faqs = ref([
    {
      questionLocaleKey: 'landing.faq.items[0].question',
      answerLocaleKey: 'landing.faq.items[0].answer',
    },
    {
      questionLocaleKey: 'landing.faq.items[1].question',
      answerLocaleKey: 'landing.faq.items[1].answer',
    },
    {
      questionLocaleKey: 'landing.faq.items[2].question',
      answerLocaleKey: 'landing.faq.items[2].answer',
    },
    {
      questionLocaleKey: 'landing.faq.items[3].question',
      answerLocaleKey: 'landing.faq.items[3].answer',
    },
    {
      questionLocaleKey: 'landing.faq.items[4].question',
      answerLocaleKey: 'landing.faq.items[4].answer',
    },
  ]);
</script>
