import cookie from 'vue-cookie';

import { callbackIterableObject, checkType, deepCopy, hasOwnProperty } from '~/plugins/aliftech-ui/utils';

/**
 * Метод отлова ошибок
 * @param {Function} callback
 * @param {*} [defaultValue]
 * @return {*}
 * */
export const catchErrors = (callback, defaultValue) => {
  try {
    return callback();
  } catch (e) {
    return defaultValue;
  }
};

/**
 * Метод преобразования объекта в строку
 * @param {Object} obj
 * @return {String}
 * */
export const objToString = obj => catchErrors(() => JSON.stringify(obj), obj + '');

/**
 * Метод преобразования строки в объект
 * @param {String} obj
 * @return {Object}
 * */
export const stringToObj = obj => catchErrors(() => JSON.parse(obj), {});

/**
 * Метод кодирования строки в uri
 * @param {String} str
 * @return {String}
 * */
export const stringToUri = str => catchErrors(() => window.encodeURI(str), str + '');

/**
 * Метод декодирования uri в строку
 * @param {String} str
 * @return {String}
 * */
export const uriToString = str => catchErrors(() => window.decodeURI(str), str + '');

/**
 * Метод преобразования строки в base64
 * @param {String} str
 * @return {String}
 * */
export const toBase64 = str => catchErrors(() => window.btoa(str), str + '');

/**
 * Распаковка строки из base64
 * @param {String} str
 * @return {String}
 * */
export const inBase64 = str => catchErrors(() => window.atob(str), str + '');

/**
 * Кодирование строки ( для токена )
 * @param {String} token
 * @return {String}
 * */
export const tokenEncoding = token => {
  const result = toBase64(stringToUri(token));
  return toBase64(result.slice(0, 5) + 'A' + result.slice(5));
};

/**
 * Получение декодированного токена
 * @param {String} token
 * @return {String}
 * */
export const tokenDecryption = token => {
  const result = inBase64(token);
  return uriToString(inBase64(result.slice(0, 5) + result.slice(6)));
};

/**
 * Метод кодирование данных для форм
 * @param {Object} data
 * @return {String}
 * */
export const formDataEncoding = data => catchErrors(() => tokenEncoding(stringToUri(objToString(data))), '');

export const setResponseCookie = (cookieKey, params, timeout) => {
  return catchErrors(() => {
    const date = (() => {
      const now = new Date();
      return new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds() + timeout
      );
    })();
    const obj = { ...params, finish: date.getTime() };
    if (cookie) {
      cookie.set(cookieKey, formDataEncoding(obj), {
        expires: timeout / 60 + 'm',
      });
    }
    return obj;
  }, params);
};

/**
 * Метод декодирования данных для форм
 * @param {String} data
 * @return {Object}
 * */
export const formDecryption = data => catchErrors(() => stringToObj(uriToString(tokenDecryption(data))), {});

export const applyDataFromCookie = (context, keys, cookieKey, callback) => {
  catchErrors(() => {
    let saved = cookie.get(cookieKey);
    if (saved) {
      saved = formDecryption(saved);
      if (checkType(saved, 'object')) {
        const prev = keys.reduce((acc, key) => {
          if (key in context) {
            acc[key] = deepCopy(context[key]);
          }
          return acc;
        }, {});
        callbackIterableObject(saved, (val, key) => {
          if (key in prev) {
            if (checkType(callback, 'function') && callback.length) {
              const result = callback(val, key);
              if (result) {
                prev[key] = result;
              }
            } else {
              prev[key] = val;
            }
          }
          for (const key in prev) {
            if (hasOwnProperty(prev, key)) {
              context[key] = prev[key];
            }
          }
        });
      }
    }
  });
};
