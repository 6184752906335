export default {
  titles: {
    help: 'Помощь',
    socials: 'Мы в социальных сетях',
    linkToPartners: 'партнеров',
    advice: `Полученный лимит можете потратить в магазинах наших {linkToPartners}`,
  },
  content: {
    contacts: ['Номер круглосуточного<br />Сall-центра', 'Номер круглосуточного<br />Сall-центра'],
    address: 'Узбекистан, Ташкент, Шахриабадский район, улица Шота Руставели, дом 12А',
  },
  links: {
    questions: 'Часто задаваемые вопросы',
    limits: 'Подробнее о лимите',
    publicOffer: 'Публичная оферта',
  },
};
