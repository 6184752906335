<template>
  <AtContainer :short="$_at_user && LandingRouteName !== route.name" class="relative mt-auto-600">
    <div
      :class="{ 'mb-20': isBannerVisible }"
      class="flex flex-wrap flex-col lg:flex-row py-12 items-center justify-between space-y-6 lg:space-y-0"
    >
      <div class="flex justify-center text-center gap-10">
        <a href="https://t.me/alif_uzbekistan" target="_blank" data-testid="telegram_link">
          <TelegramIcon />
        </a>
        <a
          href="https://instagram.com/alif.uzbekistan?igshid=YmMyMTA2M2Y="
          target="_blank"
          data-testid="instagram_link"
        >
          <InstagramIcon />
        </a>
        <a href="https://www.facebook.com/alifuzbekistan" target="_blank" data-testid="facebook_link">
          <FacebookIcon />
        </a>
        <a
          href="https://www.linkedin.com/company/aliftech/?viewAsMember=true"
          target="_blank"
          data-testid="linkedin_link"
        >
          <LinkedInIcon />
        </a>
        <a
          href="https://www.youtube.com/channel/UCUPoc9tXQfnihmPJ-k1q1kA"
          target="_blank"
          class="flex items-center justify-center"
          data-testid="youtube_link"
        >
          <YoutubeIcon />
        </a>
      </div>
      <div class="flex items-center space-x-2 font-medium text-gray-900 text-sm">
        <span>©{{ currentYear }}. Alif Nasiya</span>
        <span> · </span>
        <a
          href="/pdf/public-offer-ru-uz.pdf"
          target="_blank"
          class="flex items-center space-x-1 hover:underline"
          data-testid="public_offer_link"
        >
          <DocumentTextIcon class="w-5 h-5 text-primary-600" style="min-width: 20px" />
          <span>{{ $t('footer.links.publicOffer') }}</span>
        </a>
      </div>
    </div>
  </AtContainer>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import { DocumentTextIcon } from '@heroicons/vue/20/solid';

  import { $_at_user } from '~/shared/at-user';
  import AtContainer from '~/components/ui/AtContainer.vue';
  import TelegramIcon from '~/components/icons/TelegramIcon';
  import InstagramIcon from '~/components/icons/InstagramIcon';
  import FacebookIcon from '~/components/icons/FacebookIcon';
  import YoutubeIcon from '~/components/icons/YoutubeIcon.vue';
  import LinkedInIcon from '~/components/icons/LinkedInIcon';
  import { getYear } from 'date-fns';

  defineProps({
    isBannerVisible: {
      type: Boolean,
      default: false,
    },
  });

  const currentYear = getYear(new Date());

  const LandingRouteName = 'Landing';

  const route = useRoute();
</script>

<script>
  export default {
    name: 'BaseFooter',
  };
</script>
