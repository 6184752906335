import { computed, ref } from 'vue';

import i18n from '~/plugins/i18n';

const defaultAppLocaleKey = ref(null);
const initialAppStructure = i18n.global?.availableLocales?.reduce((acc, localeCode) => {
  return {
    ...acc,
    [localeCode]: null,
  };
}, {});
const app = ref(initialAppStructure);

export const checkAppLocaleExist = locale => {
  return Boolean(app.value?.[locale]);
};
export const setApp = (data, locale, isDefault) => {
  app.value = {
    ...app.value,
    [locale]: {
      ...app.value[defaultAppLocaleKey.value],
      ...data,
    },
  };

  defaultAppLocaleKey.value = isDefault ? locale : defaultAppLocaleKey.value;
};
export const clearApp = () => {
  Object.assign(initialAppStructure);
};

export const $_at_app = computed(() => {
  const currentLocaleCode = i18n.global.locale.value;
  return app.value?.[currentLocaleCode] || app.value?.[defaultAppLocaleKey.value];
});
