<template>
  <div class="auth-header" :class="{ 'auth-header_fixed': fixed }">
    <div class="auth-header__block flex items-center backdrop-filter backdrop-blur-xl py-3 w-full">
      <AtContainer class="w-full">
        <div class="flex justify-between items-center space-x-4">
          <div class="space-x-10 flex items-center">
            <div class="flex items-center space-x-3 lg:space-x-0">
              <button type="button" class="p-1 lg:hidden" @click="isBurgerMenuOpen = true">
                <Bars3Icon class="w-5 h-5 text-gray-500" style="min-width: 20px" />
              </button>
              <router-link :to="logoLink" class="flex items-center space-x-1.5">
                <AlifLogoIcon
                  class="w-8 h-8 md:h-9 md:w-9 md:hidden"
                  :class="{ 'hidden': compact }"
                  style="color: #39b980"
                  data-testid="logo_link"
                />
                <BaseLogo class="text-primary-600 md:block w-32 md:w-auto" :class="compact ? 'block' : 'hidden'" />
              </router-link>
            </div>
            <div class="hidden lg:flex lg:items-center lg:space-x-6">
              <router-link
                :to="{ name: 'Shops.Index' }"
                class="text-sm leading-5 text-gray-900 transition hover:text-primary-600"
                data-testid="shops_link"
                >{{ $t('app.header.shops') }}</router-link
              >
              <a
                :href="`${merchantUrl}/sign-up`"
                target="_blank"
                class="text-sm leading-5 text-gray-900 transition hover:text-primary-600"
                data-testid="partners_link"
                >{{ $t('app.header.partner') }}</a
              >
              <router-link
                :to="{ name: 'Juma' }"
                class="text-sm leading-5 text-gray-900 transition hover:text-primary-600 normal-case"
                data-testid="juma_link"
              >
                alif Juma
              </router-link>
            </div>
          </div>
          <div class="flex items-center space-x-3">
            <BaseLangChange public data-testid="language_change_icon">
              <template #title="{ language }">
                <span class="font-medium text-sm text-gray-500 hidden sm:block">
                  {{ language.title }}
                </span>
              </template>
            </BaseLangChange>
            <div class="space-x-2 flex items-center lg:flex" :class="{ 'hidden': compact }">
              <template v-if="isAuth">
                <!-- TODO: v-if (useBreakpoint) or responsive components props -->
                <AtButton
                  :to="{ name: 'App.Index' }"
                  class="lg:hidden"
                  data-testid="return_to_cabinet_button"
                  color="primary"
                  size="sm"
                  rounded
                >
                  <span class="text-xs">
                    {{ $t('app.header.returnToCabinet') }}
                  </span>
                </AtButton>
                <AtButton
                  :to="{ name: 'App.Index' }"
                  class="hidden lg:block"
                  data-testid="return_to_cabinet_button"
                  color="primary"
                >
                  {{ $t('app.header.returnToCabinet') }}
                </AtButton>
              </template>
              <template v-else>
                <!-- TODO: v-if (useBreakpoint) or responsive components props -->
                <AtButton
                  id="login_by_sms_button_test"
                  :to="{ name: AUTH_COMPONENT_NAME }"
                  class="lg:hidden"
                  data-testid="login_by_sms_button"
                  size="sm"
                  rounded
                >
                  <span class="text-xs">
                    {{ $t('system.btns.login') }}
                  </span>
                </AtButton>
                <AtButton
                  id="login_by_sms_test"
                  :to="{ name: AUTH_COMPONENT_NAME }"
                  class="hidden lg:block"
                  data-testid="login_by_sms_button"
                >
                  {{ $t('system.btns.login') }}
                </AtButton>
              </template>
            </div>
          </div>
        </div>
      </AtContainer>
    </div>
  </div>
  <AuthBurgerMenu v-model="isBurgerMenuOpen" :is-auth="isAuth" class="lg:hidden" />
</template>

<script setup>
  import { ref, computed, inject } from 'vue';
  import { Bars3Icon } from '@heroicons/vue/20/solid';

  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';

  import AtContainer from '~/components/ui/AtContainer.vue';
  import BaseLangChange from '~/components/base/BaseLangChange.vue';
  import AuthBurgerMenu from '~/components/auth/AuthBurgerMenu.vue';
  import AlifLogoIcon from '~/assets/icons/AlifLogo.vue';
  import BaseLogo from '~/components/base/Logo';
  import { AUTH_COMPONENT_NAME } from '~/constants';

  const props = defineProps({
    compact: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    isAuth: {
      type: Boolean,
      default: false,
    },
  });

  const logoLink = computed(() => (props.isAuth ? { name: 'App.Index' } : { name: 'Landing' }));

  const isBurgerMenuOpen = ref(false);

  const merchantUrl = inject('merchantUrl');
</script>

<script>
  export default {
    name: 'AppAuthHeader',
  };
</script>

<style lang="scss">
  .auth-header {
    --auth-header-height: 60px;

    @apply sticky duration-200 lg:sticky z-20;

    top: calc(calc(var(--auth-header-height)) * -1);

    @screen lg {
      --auth-header-height: 74px;
      top: 0;
    }

    &_fixed {
      top: 0;
    }

    &__block {
      background: rgba(255, 255, 255, 0.8);
      height: var(--auth-header-height);
    }
  }
</style>
