import { useAuthModel } from '~/helpers/authModel';

export default [
  {
    path: '/:locale(ru|uz)?/auth',
    component: () => import('~/layouts/AuthLayout.vue'),
    name: 'Auth',
    meta: { public: true },
    children: [
      {
        path: '',
        name: 'Auth.Registration',
        beforeEnter: to => {
          const { checkHasApplicantUuid } = useAuthModel();
          const hasApplicantUuid = checkHasApplicantUuid();

          if (hasApplicantUuid && to.name !== 'Auth.Identification') {
            return { name: 'Auth.Identification', params: { ...to.params } };
          } else if (!hasApplicantUuid && to.name !== 'Auth.Login') {
            return { name: 'Auth.Login', params: { ...to.params } };
          }
        },
        children: [
          {
            path: '',
            name: 'Auth.Login',
            component: () => import('~/views/auth/LoginPage.vue'),
          },
          {
            path: 'identification',
            name: 'Auth.Identification',
            component: () => import('~/views/auth/IdentificationIndex.vue'),
            children: [
              {
                path: 'my-id',
                name: 'Auth.Identification.MyId',
                component: () => import('~/views/auth/IdentificationMyId.vue'),
              },
              {
                path: 'ocr',
                name: 'Auth.Identification.Ocr',
                component: () => import('~/views/auth/IdentificationOcr.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
];
