export default {
  stepsLabels: {
    myid: 'Selfi',
    questionnaire: 'Profil',
    files: 'Fayllar',
    cards: 'Karta',
  },
  myid: {
    title: 'Selfi qiling',
    description: 'Biz uni pasportdagi fotosurat bilan solishtiramiz',
    request: {
      title: "Uzoq vaqt ko'rinmadingiz",
      description: "Iltimos, pasport ma'lumotlarini qayta kiriting",
    },
    enterPassportDataTitle: 'Pasport ma’lumotlaringizni kiriting',
    incorrect: {
      title: "Pasport ma'lumotlari mos kelmadi",
      description: "ltimos, hamma ma'lumotlarni to‘g‘ri kiritganingizga ishonch hosil qiling va qayta urinib ko‘ring",
    },
    actions: [
      "Telefoningizni oldingizda to'g'ri ushlab turing",
      "Ko'zlaringiz aniq ko'ringanligiga ishonch hosil qiling",
    ],
    btns: {
      selfie: 'Selfi qilish',
      start: 'Boshlash',
      selfieAgain: 'Qayta suratga olish',
    },
    notSupported: "To'g'ri kelmaydi",
    mediaDeviceError: 'Qurilmada kamera topilmadi',
    changePhoneFailed:
      'Telefon raqamni almashtirish iloji bo‘lmadi. Iltimos, Alif A’zoga Telegram orqali murojaat qiling',
  },
  titles: {
    hello: 'Xush kelibsiz!',
    step: '{stepsNumber} dan {current}-qadam',
    first: "Iltimos, saytga kirishdan oldin to'ldiring",
    files: {
      title: 'Hujjatlar',
      choice: 'Sizda pasportmi yoki ID karta?',
      sub: 'Fotosuratlarini yuklang',
      step: 'Qadam 1/3',
      passport: {
        title: 'Pasport',
        titleStep: 'Pasportning old tomoni',
        info: "Pasport va fotosuratdagi ma'lumotlar o'qilishi kerak",
        example: 'Pasport namunasi',
      },
      onFace: {
        title: 'Pasport bilan birga tushilgan rasm',
        info: 'Biz uchun pasport egasi ekanligingizni bilish juda muhimdir',
        example: 'Pasport bilan tushilgan rasm namunasi',
      },
      showExample: "Namuna ko'rsatish",
      uploaded: 'Fayl muvaffaqiyatli yuklandi',
      docType: 'Pasport turini tanlang',
      // TODO: translate
      correct: 'Нужно так',
      // TODO: translate
      incorrect: 'Так не надо',
      id: {
        card: {
          title: 'ID karta',
          subTitle: [
            "ID karta va rasmdagi ma'lumot o'qiladigan bo'lishi kerak",
            'ID kartangizni ikkala tarafini ketma-ket rasmga oling',
          ],
          files: {
            front: 'ID-kartaning oldi tarafi:',
            back: 'ID-karta orqa tarafi:',
          },
          example: 'ID karta namunasi',
        },
        onFace: {
          title: 'Karta bilan surat',
          subTitle: "Bizga karta o'zingizda ekanligini bilish muhim",
          example: 'ID karta bilan tushgan rasm namunasi',
        },
        registration: {
          title: 'Propiska (shart emas)',
          subTitle: "Propiskadagi ma'lumot o'qiladigan bo'lishi kerak",
          example: 'Propiska namunasi',
        },
      },
    },
    card: {
      title: 'Plastik karta',
      sub: 'Asosiy daromadingiz kartasini joylashtiring',
      step: 'Qadam 2/3',
    },
    questionnaire: {
      title: 'Profil',
      sub: "Iltimos, barcha maydonlarni to'ldiring",
      step: 'Qadam 3/3',
      contacts: {
        title: `Iltimos, yaqinlaringizning telefon raqamlarini qo'shing`,
        addContact: `№{number} telefon raqamini qo'shing`,
        contact: 'Kontakt {number}',
      },
    },
    personalInformation: "Shaxsiy ma'lumotlar",
    job: "Ish haqida ma'lumot",
    jobInfo: "Tashkilotning to'liq qonuniy nomini kiriting",
    other: 'Boshqalar',
    credit: ['Hozir sizning kreditingiz bormi??', 'Ha', "Yo'q"],
    phone: 'Qarindoshlaringizni raqami: {number}',
    relationDegreeInfo: 'Masalan: er/xotin, ota-ona, aka/opa',
    completed: {
      title: 'Tabriklaymiz!',
      info: 'Siz barcha kerakli qadamlarni bajardingiz',
      sub: "Bizning menejerlarimiz ma'lumotlaringizni ko'rib chiqishni boshlashdi va yaqin orada siz boshqa bo'limlarga kirishingiz mumkin",
    },
    documentType: "Sizga qulay bo'lgan hujjat turini tanlang",
    chooseIfPassport: "Agar sizda pasport bo'lsa, mana bu variantni tanlang",
    chooseIfId: "Agar sizda ID bo'lsa, mana bu variantni tanlang",
    thx: {
      title: "Siz ro'yxatdan o'tdingiz",
      msg: ["Sizning arizangiz ko'rib chiqilmoqda.", 'Javobni 5 daqiqa ichida', "raqamiga sms ko'rinishida olasiz"],
    },
  },
  fields: {
    card: 'Karta ma’lumotlari',
    cardInfo: "Shaxsiy sahifaga qo'shimcha kartalarni qo'shishingiz mumkin",
    workplace: 'Qayerda ishlaysiz?',
    profession: 'Unvoningiz',
    income: 'Oylik daromadingiz',
    sum: 'so’m',
    relativeName: 'Raqam egasining ismi',
    relationDegree: 'Kim bo’ladi sizga',
    activityArea: 'Ish yuritish sohasi',
    preferredLanguage: 'Til',
  },
  btns: {
    goQuestionnaire: 'Anketaga o’tish',
    completed: {
      goBack: 'Anketaga qaytish',
    },
    passport: 'Pasport',
    idCard: 'ID karta',
    additionalCard: "Qo'shimcha karta ulash",
    goCabinet: 'Shaxsiy kabinetga kirish',
    goStore: 'Xarid qilishda davom eting',
    loadPhoto: 'Rasmni yuklash',
    sendRequest: `So'rovni ko'rib chiqishga yuborish`,
  },
  steps: {
    documents: 'Hujjatlar',
    documentsInfo: 'Pasport',
    card: 'Karta',
    cardInfo: 'Bank kartasi',
    questionnaire: 'Foydalanuvchining profili',
    questionnaireInfo: "O'zingiz haqingizda ma'lumot",
    phonesInfo: 'Qo’shimcha telefon raqamlari',
    stepEnd: 'Barcha shartlar bajarildi',
  },
  questionnaire: {
    activityAreaFiles: {
      title: 'Limitingizni oshiring',
      clear: 'Tushunarli',
      showExample: 'Misol ko’rsatish',
      exampleTaxiTitle: 'Daromad fotosuratining namunasi',
      taxi: "Taksi haydovchilari uchun ilovalardan olingan daromadlarning skrinshotlari to'lov limitini oshirishga yordam beradi",
      military: 'Iltimos, harbiy guvohnomangizni kiriting',
    },
  },
  updatePassportId: {
    failedText:
      'Bunday pasport seriya bilan mijoz avvaldan mavjud. Iltimos, Alif A’zoga Telegram orqali murojaat qiling',
    title: 'Bu telefon raqamiga ega mijoz tizimda mavjud',
    description: 'Davom ettirish uchun pasport ma’lumotlarini yangilang',
    buttons: {
      submit: 'Pasport ma’lumotlarini o’zgartirish',
      back: 'Ortga',
    },
  },
  cards: {
    repeatCodeSuccess: 'Kod {phone} telefon raqamiga muvaffaqiyatli jo’natildi',
  },
};
