export default {
  titles: {
    app: {
      limitTitle: 'Лимит',
      next_payment: 'Следующая оплата',
      title: 'Баланс',
      installments: {
        title: 'Рассрочки',
        number: 'Номер рассрочки',
        fullyPaid: 'Полностью оплачено',
        payment: 'Следующая оплата • {date}',
        noMainCard: 'Основная карта отсутсвует',
        notFound: {
          title: 'У вас нет ни одной рассрочки.',
          active: 'У вас нет активных рассрочек',
          link: 'Перейти на Alif Shop',
        },
        show: 'Показать все рассрочки',
        hide: 'Скрыть',
      },
      applications: {
        notFound: {
          title: 'У вас нет ни одной заявки.',
        },
      },
      limit: {
        title: 'Доступно',
        recoverBtn: 'Восстановить лимит',
        increaseBtn: 'Увеличить лимит',
        limitExhausted: 'Ваш лимит исчерпан. Вы можете восстановить его.',
        waiting: 'Запрос на восстановление лимита отправлен, ожидайте ответ по SMS',
        increaseLimitWaiting: 'Запрос на увеличение лимита отправлен, ожидайте ответ по SMS',
        clear: 'Понятно',
        question: 'Что с ним можно сделать?',
        rejected: 'Запрос на восстановление лимита отклонён. Попробуйте снова!',
        increaseLimitRejected: 'Запрос на увеличение лимита отклонён.',
        availableLimit: 'Доступный лимит',
        total_limit: 'Общий лимит',
        used_limit: 'Потрачено',
        modalBtn: 'Как работает лимит?',
        modal: {
          title: 'Как работает лимит',
          description: 'Лимит — это сумма, на которую можно приобрести товары в рассрочку у наших партнеров',
        },
      },
      status: {
        title: 'Ваш статус',
      },
      shops: {
        title: 'Вы можете тратить лимит в магазинах наших партнеров',
        subTitle: 'Совершайте покупки в рассрочку в 100+ магазинах по всему Узбекистану!',
        link: 'Все магазины',
      },
      reviewProgress: {
        title: 'Спасибо, что отправили заявку',
        subTitle: 'Заявку рассмотрим в течение 5 минут.',
        subTitle2: 'Ответ получите в виде SMS.',
        awaiting: 'Cкоро получите лимит.',
        hasLimit: 'У вас есть лимит {amount} сум.',
        noLimit: 'Даже если у вас лимит 0, можете отправить заявку выбрав товар',
      },
      returnBack: {
        reviewed: 'Вы можете вернуться обратно на сайт',
        canGoBack: 'Вы можете вернуться, чтобы продолжить оформление заявки',
        goBackAndChooseItem: 'Перейдите на сайт и выберите товар',
      },
    },
    shops: {
      title: 'Магазины',
    },
    profile: {
      title: 'Настройки профиля',
      password: {
        title: 'Придумайте новый пароль',
        requirements: {
          title: 'Требования к паролю',
          subTitle: 'Пароль должен соответствовать следующим пунктам:',
          list: [
            'Латинские буквы',
            'Длина пароля минимум 8 символов',
            'Минимум 1 заглавная буква',
            'Минимум 1 строчная буква',
            'Минимум 1 цифра',
          ],
        },
        messages: {
          success: 'Ваш пароль успешно изменён',
        },
      },
      phone: {
        title: 'Изменить номер телефона',
        newPhone: 'Укажите новый номер',
        myIdDescription: 'Нам нужно убедиться, что это вы хотите сменить номер телефона',
        errors: {
          myIdTimeout: {
            title: 'Срок действия идентификации истёк',
            message: 'Пожалуйста пройдите её снова',
          },
        },
      },
      photo: {
        title: 'Фото профиля',
        subTitle: 'Файл не должен превышать 3мб',
        move: 'Перетащите файл для того чтобы обновить фото профиля',
        messages: {
          success: 'Фото профиля успешно обновлено',
          delete: 'Фото профиля успешно удалено',
        },
      },
      cards: {
        title: 'Привязанные карты',
        attached: 'Карта успешно привязана',
        modal: 'Добавление карты',
        limit: {
          error: 'Количество попыток исчерпано',
          count: 'Осталось попыток {count}',
        },
        payments: {
          paid: 'Оплата прошла успешно',
          initial: 'Изначальная дата оплаты',
          actual: 'Фактическая дата оплаты',
        },
      },
      acts: {
        noData: 'У вас нет подписанных актов',
      },
    },
    installment: {
      postponement: 'Отсрочка за • {date}',
      postponementWithShift: 'Отсрочка со сдвигом',
      shift: {
        info: ['Дата оплаты измениться на выбранную вами дату для', 'всех', 'следующих оплат, а не только для одной.'],
        instruction: 'Поставьте галочку, если вы хотите изменить дату ежемесячной оплаты.',
      },
      commission: 'Комиссия',
      title: 'Рассрочка',
      info: 'Информация о рассрочке',
      amount: 'Сумма рассрочки',
      applicationAmount: 'Сумма заявки',
      detail: {
        title: 'Детали рассрочки',
        issued: 'Выдана',
        act: 'Скачать акт',
        receiptLink: 'Показать чек',
        certificateOfClosure: 'Справка о закрытии рассрочки',
      },
      issued: 'Когда выдана',
      month: 'Срок',
      paid: {
        amount: 'Всего оплачено',
        month: 'Всего оплачено за',
      },
      next: 'След. платёж',
      after: 'Через {count}',
      before: 'Было {day} назад',
      progress: 'Прогресс оплаты рассрочки',
      charts: {
        payments: 'График оплаты',
        history: 'История оплаты',
      },
      fine: {
        title:
          'За неуплату рассрочки вовремя вам могут начислить штраф в сумме {penaltyPercent} от ежемесячной оплаты кредита.',
        description: 'Просим оплачивать рассрочку вовремя.',
      },
      delayed: {
        detail: 'Детали отсрочки',
        days: 'Отсрочено на {count}',
        daysShift: 'Отсрочено со сдвигом на {days}',
        title: 'Вы просрочили платёж на {days} дней',
        description:
          'Пожалуйста, внесите оплату. За неуплату вовремя будет начислен штраф в 1% от ежемесячной оплаты рассрочки.',
        success: 'Запрос на отсрочку создан!',
      },
      dates: {
        payment: 'Дата платежа',
        delay: 'Дата',
        requested: 'Запрошено',
        active: 'Действует до',
        shift: 'Со сдвигом',
        delayed: 'Фактически отсрочено на',
        overdue: 'Вы отсрочили платёж на',
      },
      firstInstallment: 'Первый взнос',
      table: {
        payments: ['Дата', 'Сумма', 'Оплачено'],
        history: ['Сумма', 'Тип оплаты / Источник', 'Дата и время', 'Погашение за'],
        penalties: ['Дата', 'Баланс', 'Сумма штрафа'],
      },
      payment: {
        title: 'Детали платежа',
        amount: 'Сумма оплаты',
        payFor: 'Оплата за {date}',
        setAmount: 'Укажите свою сумму',
        labels: {
          'active_payment direct debit': 'Автосписание',
          'problem_payment direct debit': 'Автосписание',
          'overdue_payment direct debit': 'Автосписание',
          'online direct debit': 'Alif Nasiya',
          'credits force withdraw': 'Алиф списание',
          'credits safe withdraw': 'Алиф списание',
          'call_center direct debit': 'Колл центр',
          'calls force withdraw': 'Колл центр',
          'calls safe withdraw': 'Колл центр',
          application: 'Платежная система',
          bank: 'Банк',
          hold: 'Первоначальный взнос',
          'penalty force withdraw': 'Штраф',
          'force withdraw': 'Алиф списание',
          'restored after error': 'Восстановленные вручную платежи',
          'manual direct debit': 'Алиф списание',
          'withdraw_force direct debit': 'Алиф списание',
        },
      },
      noHistory: 'У вас нет истории оплат',
      penalties: {
        amount: 'Сумма штрафов',
        info: 'Вы сможете запросить прощение штрафов после полного погашения рассрочки',
        noData: 'У вас нет штрафов',
        totalAmount: 'Общая сумма штрафа',
        modalTitle: 'Штрафы',
        successRequest: 'Запрос на прощение штрафов был отправлен. Ожидайте ответ по смс.',
        success: 'Штрафы были понижены до {amount} сум.',
      },
      successPay: 'Платеж успешно совершен',
    },
    applications: {
      status: {
        NEW: 'Новый',
        REVIEWING: 'Рассматривается',
        APPROVED: 'Одобрено',
        REJECTED: 'Отказано',
        COMPLETED: 'Оформлено',
        CANCELLED: 'Отменено',
        DELIVERING: 'На доставку',
        DELETED: 'Удалено',
      },
      modal: {
        title: 'Детали заявки',
        storeName: 'Магазин',
        monthlyPayment: 'Ежемесячная оплата',
        sumMonth: 'сум/мес',
        month: 'Месяц',
        commission: 'Коммисия',
        credit: 'Рассрочка',
        table: {
          title: 'Товары',
          name: 'Наименование',
          cost: 'Цена',
          quantity: 'Количество',
          totalAmount: 'Общая сумма',
        },
      },
    },
    accountReview: {
      title: 'Ваш аккаунт на рассмотрении',
      text: 'Пожалуйста, дождитесь окончания рассмотрения, чтобы получить лимит',
    },
    banners: {
      alifshop: {
        title: 'Купить в рассрочку онлайн на Alif Shop',
        subTitle: 'Бесплатная доставка до двери.',
        subTitle2: 'Более 100 магазинов со всего Узбекистана в 1 месте',
        button: 'Перейти в alifshop',
      },
      partners: {
        title: 'Купить в рассрочку в магазинах партнеров',
        subTitle: 'Помощь консультанта.',
        subTitle2: 'Более 2000 наших партнеров по всему Узбекистану',
        button: 'Список магазинов',
      },
    },
  },
  doughnut: {
    left: 'Осталось',
    paid: 'Оплачено',
  },
  header: {
    main: 'Главная',
    shops: 'Магазины',
    partner: 'Стать партнёром',
    applications: 'Заявки',
    installments: 'Рассрочки',
    cards: 'Карты',
    returnToCabinet: 'Вернуться в кабинет',
    aboutUs: 'О нас',
  },
  fields: {
    profile: {
      newPassword: 'Новый пароль',
      repeatNewPassword: 'Новый пароль повторно',
    },
    shops: {
      city: 'Город',
      district: 'Район',
      allCity: 'Все города',
      category: 'Категория',
      shopName: 'Название магазина',
      noCategory: 'Без категории',
    },
    applications: {
      title: 'Заявки',
      amount: 'Цена',
      date: 'Дата подачи',
      moreInfo: 'Подробнее о заявке',
    },
    payment: {
      customAmount: 'Ввести свою сумму оплаты',
    },
    amount: 'Сумма',
    amountPlaceholder: 'Введите сумму',
    other: 'Другая сумма',
    calendar: 'Выберите дату',
    shift: 'Со сдвигом',
    cause: 'Причина',
    penalties: {
      placeholder: 'Введите комментарий (необязательно)',
    },
    delays: {
      title: 'Отсрочка',
      description: 'Введите описание (Обязательно)',
      accepted: 'Ваш запрос на отсрочку принят',
      instruction: 'Пожалуйста, подождите пока мы рассмотрим ваш запрос. Вы получите ответ по СМС.',
      why: 'Почему вам нужна отсрочка?',
    },
    errors: {
      date: "Поле 'дата' обязательно для заполнения",
      reason: "Поле 'Причина' обязательно для заполнения",
      note: "Поле 'Комментарий' обязательно для заполнения",
    },
  },
  btns: {
    profile: {
      changePhone: 'Изменить номер',
      photo: {
        change: 'Изменить фото',
        delete: 'Удалить фото',
      },
      cards: {
        add: 'Добавить карту',
      },
      acts: {
        open: 'Открыть акт',
      },
    },
    pay: 'Оплатить',
    cancel: 'Отменить',
    requestDeferral: 'Запросить отсрочку',
    request: 'Запросить',
    requestPenalty: 'Запросить понижение',
    changePhone: 'Сменить номер',
    goBackToSite: 'Вернуться на сайт',
    shops: {
      spoiler: {
        visible: 'Скрыть',
        collapse: 'Показать все',
      },
      type: {
        allMobile: 'Оффлайн и онлайн',
        all: 'Все',
        online: 'Онлайн',
        offline: 'Оффлайн',
      },
    },
  },
  messages: {
    profile: {
      password: 'Новый пароль был успешно применен',
      phone: 'Номер телефона успешно изменён',
    },
  },
  tabs: {
    profile: {
      cards: 'Карты',
      settings: 'Настройки',
      acts: 'Мои акты',
    },
    installment: {
      payments: 'Оплаты',
      information: 'Информация',
    },
    charts: {
      payments: 'График оплаты',
      history: 'История оплаты',
    },
  },
  modals: {
    confirmPay: 'Вы действительно хотите оплатить',
  },
  problemCase: {
    panel: {
      title: 'Недоволен товаром',
    },
    title: 'Недоволен товаром',
    fileInputLabel: 'Каким товаром недовольны?',
    reasonsBlockLabel: 'Выберите причину',
    reasons: [
      'Товар бракованный',
      'Не понравился товар',
      'Привезли не тот товар',
      'Товар не соответствует характеристикам',
      'Другое',
    ],
    reasonsInputPlaceholder: 'Введите причину',
    buttonText: 'Отправить заявку на возврат',
    form: {
      selectFile: 'Выберите файлы',
      selectAnother: 'Загрузить другой файл',
      selectMore: 'Добавить еще файлы',
    },
    successText: 'Запрос на возврат создан!',
    error: {
      description: 'Кредит не найден',
      btn: 'Вернутся на главную',
    },
  },
  reject: {
    title: 'Сделать возврат',
    form: {
      title: ['Какие товары хотите вернуть?', 'Выбрано {count}'],
      selectFile: 'Выберите файлы',
      selectAnother: 'Загрузить другой файл',
      selectMore: 'Добавить еще файлы',
      reasonLabel: 'Выберите причину возврата',
      typeReason: 'Введите причину',
      btn: 'Отправить заявку на возврат',
      selected: 'Выбрано',
    },
    modal: {
      title: 'Вы точно хотите отправить заявку на возврат?',
      itemsCount: 'Кол-во товаров',
      totalAmount: 'Общая сумма',
      btn: 'Отправить заявку',
    },
    success: {
      title: 'Ваша заявка на возврат отправлена',
      description: 'Пожалуйста, подождите пока мы рассмотрим ваш запрос. Скоро с вами свяжется менеджер',
      btn: 'Вернутся на главную',
    },
    hint: {
      title: 'Как правильно сделать возврат',
      items: ['Выберите товар, который хотите вернуть.', 'Загрузите фото товара, либо заключение сервисного центра'],
      showExample: 'Показать пример',
      modal: {
        title: 'Примеры',
        exampleTitles: ['Фото бракованного товара:', 'Заключение сервисного центра:'],
        button: 'Понятно',
      },
    },
  },
  expenses: {
    panel: {
      title: 'Ваше дело о неоплате рассрочки было передано в суд',
      button: 'Посмотреть издержки',
    },
    modal: {
      title: 'Издержки',
      totalAmount: 'Общая сумма издержек',
      name: 'Название',
      value: 'Сумма штрафа',
      notaryFeeExpense: 'Прочие расходы',
      stateDutyExpense: 'Гос.пошлина',
      postalServiceExpense: 'Почтовые расходы',
      courtPenaltyExpense: 'Штраф',
      notaryPenaltyExpense: 'Штраф',
      close: 'Закрыть',
    },
  },
};
