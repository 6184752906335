export default {
  promotion: 'Aksiya',
  alifshopBanner: {
    title: 'Alif Shopda onlayn xarid qiling',
    button: "Alif Shopga o'tish",
  },
  banner: {
    title: "{name}-da muddatli to'lovga sotib oling",
    description:
      "O‘zbekiston bo‘ylab turli hamkorlarimizdan mahsulotlarni 24 oygacha muddatli to'lovga sotib olishingiz mumkin",
    button: 'Qanday xarid qilaman?',
  },
  howToBuy: {
    title: "Muddatli to'lovga qanday sotib olish mumkin?",
    items: [
      {
        title: "Ro'yxatdan o'ting va limitga ega bo'ling",
        button: 'Roʻyxatdan oʻtish',
      },
      {
        title: "Hamkorimiz do'koniga keling va mahsulotni tanlang",
        button: "Barcha do'konlar",
      },
      {
        title: "Alif Shop onlayn do'konida",
        button: "Alif Shopga o'tish",
      },
    ],
  },
  nearestShops: {
    title: "Eng yaqin do'konlar",
    button: "Barchasini ko'rish",
    metresUnits: ['metr', 'metr', 'metr'],
    kilometresUnit: 'km',
    geolocationAllow: "Yaqin-atrofdagi do'konlarni ko'rish uchun geolokatsiyangizga ruxsat bering",
  },
  filter: 'Filtr',
};
