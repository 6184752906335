import VueCookies from 'vue-cookies';

// import app from "../main";
// import router from "~/router";
import { apiClient } from '~/services/apiClient';

import { formatMoney } from '~/utils';
import { setResponseCookie } from '~/utils/cookie';

const END_POINT = '/profile';

/**
 *
 * @returns
 */
const getMe = () => apiClient.get(`${END_POINT}/me`);

/**
 *
 * @returns
 */
const getFiles = () => apiClient.get(`${END_POINT}/files`);
/**
 *
 * @returns
 */
const getLimits = () =>
  apiClient.get(`${END_POINT}/limits`).then(response => {
    const data = response.data ?? {};
    const result = {
      _current_amount: formatMoney(Number((data.current_amount / 100).toFixed(2)), 0),
      _initial_amount: formatMoney(Number((data.initial_amount / 100).toFixed(2)), 0),
    };

    response.data = { ...data, ...result };
    return response;
  });

/**
 *
 * @param {*} file
 * @param {*} type
 * @returns
 */
const sendFiles = (file, type) => apiClient.post(`${END_POINT}/files/${type}`, file);

/**
 *
 * @param {*} id
 * @returns
 */
const deleteFile = id => apiClient.post(`${END_POINT}/files/${id}/delete`);

/**
 *
 * @returns
 */
const getRecoverLimitsStatus = () => apiClient.get(`${END_POINT}/limits/recover`);

export const getUpgradeLimitsStatus = () => apiClient.get(`${END_POINT}/limits/upgrade`);

/**
 *
 * @returns
 */
const recoverLimits = () => apiClient.post(`${END_POINT}/limits/recover`);

const upgradeLimits = () => apiClient.post(`${END_POINT}/limits/upgrade`);

/**
 *
 * @returns
 */
const getApplications = () =>
  apiClient.get(`${END_POINT}/applications`).then(response => {
    const data = response.data.data ?? [];
    const result = data.reduce((acc, application) => {
      const template = {
        ...application,
        items: application.items.map(item => {
          return {
            ...item,
            _price: formatMoney(Number((item.price / 100).toFixed(2)), 0),
          };
        }),
        _amount: formatMoney(Number((application.amount / 100).toFixed(2))),
      };

      acc.push(template);

      return acc;
    }, []);

    response.data.data = [...result];
    return response;
  });

/**
 * Метод обновления пароля
 * @param {PasswordUpdate} params
 * @return {Promise}
 * */
const updatePassword = params => {
  return apiClient.post(`${END_POINT}/update-password`, params);
};
/**
 * Метод обновление номера телефона ( запрос на идентификацию клиента )
 * @param {Object} payload
 * @param {FormData} payload.photo_face
 * @return {Promise}
 * */
const requestIdentification = payload => {
  return apiClient.post(`${END_POINT}/phone-update/request-identification`, payload);
};
/**
 * Метод обновление номера телефона ( получение кода + проверка статуса май айди )
 * @param {Object} payload
 * @param {String} payload.phone
 * @param {Number} timeout
 * @return {Promise}
 * */
const getOtpCode = (payload, timeout = 600) => {
  return apiClient.post(`${END_POINT}/phone-update/send-otp`, payload).then(res => {
    if (res?.status === 200) {
      const phone = payload?.phone;
      const result = setResponseCookie(
        'online-profile-update-phone',
        { ...payload, ...(phone ? { phone } : {}) },
        timeout
      );

      return Promise.resolve({
        ...res,
        data: {
          ...res.data,
          phone,
          finish: result?.finish ?? new Date().getTime(),
        },
      });
    }

    return res;
  });
};
/**
 * Метод обновление номера телефона ( проверка статуса май айди )
 * @param {Object} params
 * @param {String} payload.phone
 * @return {Promise}
 * */
const checkIdentification = payload => {
  return apiClient.post(`${END_POINT}/phone-update/check-identification-status`, payload);
};

/**
 * Метод обновление номера телефона ( подтверждение кода )
 * @param {Object} payload
 * @param {String} payload.phone
 * @param {String} payload.otp
 * @return {Promise}
 * */
const verifyOtpCode = payload => {
  return apiClient.post(`${END_POINT}/phone-update/verify-otp`, payload).then(res => {
    VueCookies.remove('online-profile-update-phone');
    return res;
  });
};

/**
 *
 * @returns
 */

const getCards = clientId =>
  apiClient.get(`${END_POINT}/cards`, { params: { client_id: clientId } }).then(response => {
    return response;
  });

/**
 *
 * @returns
 */

const setMainCard = (cardId, clientId) =>
  apiClient.put(`${END_POINT}/cards/${cardId}/set-main`, { client_id: clientId }).then(response => {
    return response;
  });

/**
 * Метод добавления новой карты
 * @param {CardAddGetCode} params
 * @param {Number} timeout
 * @return {Promise}
 * */
const addCardGetCode = (params, timeout = 600) => {
  return apiClient.post(`${END_POINT}/cards/request-attach`, params).then(res => {
    const phone = res?.data?.phone ?? undefined;
    const result = setResponseCookie('online-form-cards-data', { ...params, ...(phone ? { phone } : {}) }, timeout);
    return Promise.resolve({
      ...res,
      data: {
        ...res.data,
        finish: result?.finish ?? (() => new Date().getTime())(),
      },
    });
  });
};

/**
 * Метод подтверждения карты
 * @param {CardAdd} params
 * */
const addCard = params => {
  return apiClient.post(`${END_POINT}/cards/attach`, params).then(res => {
    VueCookies.remove('online-form-cards-data');
    return res;
  });
};

/**
 *
 * @returns
 * https://mocki.io/v1/80978c64-36c4-4a81-85c4-8c9af420c675
 */
const getMyIdIdentificationStatus = () => apiClient.get(`${END_POINT}/my-id-identification-status`);
/**
 *
 * @returns
 */
const getQuestionnaries = () => apiClient.get(`${END_POINT}/questionnaires/full`);
/**
 *
 * @returns
 */
const createQuestionnaries = body => apiClient.post(`${END_POINT}/questionnaires/full`, body);

/**
 *
 * @returns
 */
const requestReview = () => apiClient.post(`${END_POINT}/request-review`);

/**
 *
 * @returns
 */
const getApplication = id => apiClient.get(`${END_POINT}/applications/${id}`);

/**
 *
 * @returns
 */
const getFile = id => apiClient.get(`${END_POINT}/files/${id}`);

/**
 *
 * @returns
 */
const updateProfile = payload => apiClient.post(`${END_POINT}/update`, payload);

/**
 *
 * @returns
 */
const getPassportType = () => apiClient.get(`${END_POINT}/files/get-passport-type`);

const uploadActivityAreaFile = payload => apiClient.post(END_POINT + '/files/per-activityarea', payload);

export {
  uploadActivityAreaFile,
  getMe,
  getFiles,
  sendFiles,
  deleteFile,
  getLimits,
  getRecoverLimitsStatus,
  recoverLimits,
  upgradeLimits,
  getApplications,
  updatePassword,
  verifyOtpCode,
  getCards,
  setMainCard,
  addCardGetCode,
  addCard,
  getMyIdIdentificationStatus,
  getQuestionnaries,
  createQuestionnaries,
  requestReview,
  getOtpCode,
  requestIdentification,
  checkIdentification,
  getApplication,
  getFile,
  updateProfile,
  getPassportType,
};
