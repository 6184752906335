<template>
  <div class="auth-burger-menu">
    <div
      class="p-4 pt-6 bg-white fixed top-0 left-0 h-full w-10/12 z-50 flex flex-col transform transition ease-in duration-300"
      :class="modelValue ? 'translate-x-0' : '-translate-x-full'"
    >
      <div class="flex items-center justify-between">
        <router-link to="/" class="mr-10" data-testid="base_logo_link" @click="toggle">
          <BaseLogo />
        </router-link>
        <XMarkIcon class="w-6 h-6 cursor-pointer" data-testid="close_icon" @click="toggle" />
      </div>
      <div class="flex items-center gap-2 mt-3">
        <template v-if="isAuth">
          <AtButton
            :to="{ name: 'App.Index' }"
            color="primary"
            class="w-full"
            data-testid="return_to_cabinet_button"
            @click="toggle"
          >
            {{ $t('app.header.returnToCabinet') }}
          </AtButton>
        </template>
        <template v-else>
          <AtButton
            class="w-full whitespace-nowrap"
            :to="{ name: AUTH_COMPONENT_NAME }"
            data-testid="login_by_sms_button"
            @click="toggle"
            >{{ $t('system.btns.login') }}</AtButton
          >
        </template>
      </div>
      <router-link
        :to="{ name: 'Shops.Index' }"
        class="flex items-center mt-4 justify-between text-sm leading-5 font-normal text-gray-700 bg-gray-100 h-10 rounded-xl p-2.5"
        data-testid="shops_link"
        @click="toggle"
      >
        <span class="text-gray-700">
          {{ $t('app.header.shops') }}
        </span>
        <ChevronRightIcon class="w-5 h-5" />
      </router-link>
      <router-link
        :to="{ name: 'Juma' }"
        class="flex items-center mt-4 justify-between text-sm leading-5 font-normal bg-gray-100 h-10 rounded-xl p-2.5"
        data-testid="juma_link"
        @click="toggle"
      >
        <span class="text-gray-700"> alif Juma </span>
        <ChevronRightIcon class="w-5 h-5 text-gray-700" />
      </router-link>
      <a
        :href="`${merchantUrl}/sign-up`"
        target="_blank"
        class="flex items-center mt-auto justify-center text-sm leading-5 font-normal text-gray-700 bg-gray-100 h-10 rounded-xl p-2.5"
        data-testid="partners_link"
      >
        {{ $t('app.header.partner') }}
      </a>
      <a
        href="tel: +998 55 512 12 12"
        class="flex items-center mt-2 justify-center text-sm leading-5 font-normal text-gray-700 bg-gray-100 h-10 rounded-xl p-2.5"
        data-testid="number_link"
      >
        +998 55 512 12 12
      </a>
    </div>
    <transition name="auth-burger-menu__shadow" appear>
      <div v-if="modelValue" class="bg-black fixed top-0 w-full h-full opacity-50 z-40" @click="toggle" />
    </transition>
  </div>
</template>

<script setup>
  import { inject } from 'vue';
  import { ChevronRightIcon, XMarkIcon } from '@heroicons/vue/24/outline';

  import BaseLogo from '~/components/base/Logo';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import { AUTH_COMPONENT_NAME } from '~/constants';

  const emit = defineEmits(['update:modelValue']);
  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
    isAuth: {
      type: Boolean,
      default: false,
    },
  });

  const toggle = () => {
    emit('update:modelValue', !props.modelValue);
  };

  const merchantUrl = inject('merchantUrl');
</script>

<script>
  export default {
    name: 'AuthBurgerMenu',
  };
</script>

<style lang="scss" scoped>
  .auth-burger-menu {
    &__shadow {
      &-enter-active,
      &-leave-active {
        transition: opacity 0.5s ease;
      }

      &-enter-from,
      &-leave-to {
        opacity: 0;
      }
    }
  }
</style>
