export default function install() {
  if (import.meta.env.VITE_ENVIRONMENT === 'production') {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-NRWJ626');
  }
}

export function gaRegisterEvent(eventName) {
  if (window.dataLayer?.push && import.meta.env.VITE_ENVIRONMENT === 'production') {
    window.dataLayer.push({ 'event': eventName });
  }
}
