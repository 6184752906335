export default {
  titles: {
    login: 'Kirish',
    hero: "Muddatli to'lovlarni tez va qulay tarzda oling va to'lang",
    restore: {
      title: 'Parolni tiklash',
      sub: 'O’z raqamingizni yozib qoldiring',
      send: 'Raqamingizga parolni tiklash uchun kod jo’natildi',
      restored: "Arizangiz bo'yicha kirish uchun parol tiklandi",
    },
    register: "Saytda ro'yxatdan o'tish",
    registerSuccess: "Ro'yxatdan o'tish muvaffaqiyatli amalga oshirildi",
    smsConfirm: 'SMS tasdiqlash',
    sendCode: 'Kod {phone} raqamiga yuborildi',
    setPhone: {
      another: 'Boshqa raqamni kiriting',
    },
    setPassword: {
      title: "Iltimos, parol o'rnating",
      btn: "Parol o'rnating",
    },
    hasAccount: {
      title: 'Shaxsiy sahifangiz bormi?',
      btn: 'Kirish',
    },
    terms:
      'Davom etish orqali <a class="text-online-500" target="_blank" href="{offerLink}">{offer}</a> ' +
      'shartlarini qabul etasiz va o\'zingiz haqingizdagi <a class="text-online-500" target="_blank" href="{cbReportLink}">{cbReport}</a> ' +
      'olinishiga rozilik bildirasiz',
    termConditions: ['Men', 'oferta shartlariga', 'va men haqimda', 'kredit hisobotini', 'olinishiga roziman'],
    updatePhone: {
      passportId: 'Pasport seriyasi va raqamini kiriting',
    },
    ocrFailedAlert: {
      title: 'Pasport ma’lumotlari topilmadi',
    },
    autoAcceptTermConditions: [
      "Avtorizatsiyadan o'tish orqali siz",
      'taklif shartlariga',
      "va o'zingiz haqingizda",
      'kredit hisobotini',
      'olishga rozilik bildirasiz',
    ],
  },
  btns: {
    rememberMe: 'Meni yodda tutish',
  },
  links: {
    requestPassword: 'Parolni yodingizdan chiqardingizmi?',
    register: ['Akkauntingiz yo’qmi?', "Ro'yxatdan o'ting"],
    hasAccount: ['Yoki', 'akkauntga kirish'],
    login: {
      sms: 'SMS kod orqali kirish',
      password: 'Parol orqali kirish',
    },
    offer: 'oferta',
    cbReport: 'kredit hisoboti',
    comeIn: 'Kirish',
    updatePhone: 'Raqamni o’zgartirish',
  },
};
