import AppLayout from '~/layouts/AppLayout.vue';

export default [
  {
    path: 'app',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'App.Index',
        component: () => import('~/views/app/AppIndexPage.vue'),
      },
      {
        path: 'applications',
        name: 'App.Applications',
        component: () => import('~/views/app/AppApplicationsPage.vue'),
      },
      {
        path: 'installments',
        name: 'Installments.Index',
        component: () => import('~/views/app/installments/InstallmentsIndexPage.vue'),
      },
      {
        path: 'installments/:id',
        name: 'Installments.Show',
        component: () => import('~/views/app/installments/InstallmentsShowPage.vue'),
      },
      {
        path: 'installments/:id/cards',
        name: 'Installments.Show.Cards',
        component: () => import('~/views/app/installments/InstallmentsCardsPage.vue'),
      },
      {
        path: 'installments/:id/create-delay',
        name: 'Installments.Show.CreateDelay',
        component: () => import('~/views/app/installments/InstallmentsCreateDelayPage.vue'),
      },
      {
        path: 'installments/:id/create-reject',
        name: 'Installments.Show.CreateReject',
        component: () => import('~/views/app/installments/InstallmentsCreateRejectPage.vue'),
      },
      {
        path: 'installments/:id/create-problem-case',
        name: 'Installments.Show.CreateProblemCase',
        component: () => import('~/views/app/installments/InstallmentsCreateProblemCasePage.vue'),
      },
      {
        path: 'installments/:id/create-reject/success',
        name: 'Installments.Show.CreateRejectSuccess',
        component: () => import('~/views/app/installments/InstallmentsCreateRejectSuccessPage.vue'),
      },
      {
        path: 'installments/:id/custom-amount',
        name: 'Installments.Show.Custom',
        component: () => import('~/views/app/installments/InstallmentsCustomAmountPage.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('~/views/app/profile/ProfileIndexPage.vue'),
      },
      {
        path: 'profile/change-password',
        name: 'Profile.ChangePassword',
        component: () => import('~/views/app/profile/ProfileChangePasswordPage.vue'),
      },
      {
        path: 'profile/change-phone',
        name: 'Profile.ChangePhone',
        component: () => import('~/views/app/profile/ProfileChangePhonePage.vue'),
      },
      {
        path: 'cards',
        name: 'App.Cards',
        component: () => import('~/views/app/AppCardsPage.vue'),
      },
      {
        path: 'cards/add',
        name: 'App.Cards.Add',
        component: () => import('~/views/app/AppCardsAddPage.vue'),
      },
      {
        path: 'partner',
        name: 'Forms.Partner',
        component: () => import('~/views/success/PartnerPage.vue'),
        beforeEnter: () => {
          if (sessionStorage.getItem('source')) return true;
          else return { name: 'App.Index' };
        },
      },
    ],
  },
];
