<script setup></script>

<template>
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.0645 2.00484C22.1982 0.975009 20.5987 0.554932 17.5441 0.554932H6.45569C3.33112 0.554932 1.70454 1.00211 0.841471 2.09852C0 3.16753 0 4.74262 0 6.9226V11.0776C0 15.3009 0.9984 17.4452 6.45569 17.4452H17.5442C20.1931 17.4452 21.661 17.0745 22.6106 16.1657C23.5845 15.2337 24 13.712 24 11.0776V6.9226C24 4.62363 23.9349 3.03924 23.0645 2.00484ZM15.4081 9.57374L10.3729 12.2053C10.2603 12.2641 10.1372 12.2933 10.0143 12.2933C9.87507 12.2933 9.73618 12.2559 9.61316 12.1814C9.38152 12.041 9.24008 11.79 9.24008 11.5191V6.27289C9.24008 6.00254 9.38113 5.75171 9.61231 5.61127C9.84356 5.47083 10.1312 5.46123 10.3711 5.58587L15.4063 8.20048C15.6625 8.33349 15.8234 8.59795 15.8237 8.88649C15.8241 9.17527 15.6639 9.44012 15.4081 9.57374Z"
      fill="currentColor"
    />
  </svg>
</template>

<style></style>
