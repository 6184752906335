import {
  createElementBlock as _createElementBlock,
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '180',
        height: '52',
        viewBox: '0 0 180 52',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          d: 'M173.334 0H6.66668C2.98476 0 0 2.91015 0 6.5V45.5C0 49.0898 2.98476 52 6.66668 52H173.334C177.015 52 180 49.0898 180 45.5V6.5C180 2.91015 177.015 0 173.334 0Z',
          fill: 'black',
        }),
        _createElementVNode('path', {
          d: 'M173.334 1.04C174.819 1.04 176.243 1.61525 177.293 2.6392C178.344 3.66314 178.933 5.05191 178.933 6.5V45.5C178.933 46.948 178.344 48.3369 177.293 49.3609C176.243 50.3849 174.819 50.96 173.334 50.96H6.66668C5.18145 50.96 3.75708 50.3849 2.70687 49.3609C1.65667 48.3369 1.06667 46.948 1.06667 45.5V6.5C1.06667 5.05191 1.65667 3.66314 2.70687 2.6392C3.75708 1.61525 5.18145 1.04 6.66668 1.04H173.334ZM173.334 0H6.66668C4.89856 0 3.20285 0.684818 1.95263 1.90381C0.70238 3.1228 0 4.77609 0 6.5V45.5C0 47.224 0.70238 48.8772 1.95263 50.0963C3.20285 51.3151 4.89856 52 6.66668 52H173.334C175.101 52 176.798 51.3151 178.047 50.0963C179.298 48.8772 180 47.224 180 45.5V6.5C180 4.77609 179.298 3.1228 178.047 1.90381C176.798 0.684818 175.101 0 173.334 0Z',
          fill: '#A6A6A6',
        }),
        _createElementVNode('path', {
          d: 'M37.6179 25.0829C37.6309 24.0848 37.8961 23.1063 38.3886 22.2382C38.8813 21.3702 39.5855 20.6409 40.4359 20.118C39.8957 19.3466 39.183 18.7118 38.3546 18.2639C37.5262 17.816 36.6047 17.5675 35.6634 17.538C33.6555 17.3273 31.7089 18.7395 30.6856 18.7395C29.6426 18.7395 28.0673 17.5589 26.3707 17.5939C25.2734 17.6293 24.204 17.9483 23.2667 18.52C22.3294 19.0917 21.5561 19.8964 21.0223 20.8557C18.7097 24.8596 20.4347 30.744 22.65 33.9803C23.7583 35.5652 25.0536 37.3355 26.7485 37.2727C28.4072 37.2039 29.0265 36.215 31.0286 36.215C33.012 36.215 33.5933 37.2727 35.3226 37.2328C37.1023 37.2039 38.2236 35.6411 39.2931 34.0412C40.0895 32.9119 40.7023 31.6638 41.1087 30.3432C40.0748 29.9059 39.1924 29.1739 38.5717 28.2385C37.951 27.303 37.6193 26.2056 37.6179 25.0829Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M34.3512 15.409C35.3216 14.2441 35.7997 12.7469 35.6838 11.2351C34.2013 11.3909 32.8319 12.0994 31.8484 13.2196C31.3675 13.7669 30.9992 14.4035 30.7646 15.0932C30.5299 15.7829 30.4334 16.512 30.4808 17.239C31.2223 17.2466 31.9559 17.0859 32.6264 16.7689C33.2967 16.4519 33.8865 15.987 34.3512 15.409Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M51.9305 14.2649H52.8845C53.0487 14.2854 53.2154 14.2716 53.3739 14.2244C53.5326 14.1773 53.6798 14.0977 53.8063 13.9909C53.9325 13.8841 54.0353 13.7521 54.1083 13.6035C54.181 13.4549 54.2226 13.2929 54.2297 13.1276C54.2297 12.4696 53.7344 12.0445 52.895 12.0445C52.7266 12.021 52.5554 12.0315 52.3912 12.0753C52.2271 12.119 52.0734 12.1953 51.9392 12.2993C51.8048 12.4035 51.693 12.5336 51.6098 12.6816C51.5266 12.8298 51.474 12.9932 51.4553 13.162H50.4075C50.4256 12.8555 50.5066 12.556 50.6453 12.2823C50.7842 12.0084 50.9778 11.766 51.2144 11.5703C51.4507 11.3746 51.725 11.2296 52.0202 11.1444C52.3151 11.0593 52.6244 11.0357 52.9287 11.0753C54.3336 11.0753 55.3227 11.8618 55.3227 12.9939C55.3258 13.3731 55.1991 13.742 54.9633 14.039C54.7273 14.3361 54.3967 14.5432 54.0265 14.6258V14.7099C54.4655 14.7293 54.8787 14.9218 55.1761 15.245C55.4733 15.5683 55.6307 15.9962 55.6134 16.4352C55.5808 16.7508 55.4857 17.0566 55.3335 17.3349C55.1812 17.6132 54.9752 17.8583 54.727 18.0559C54.4788 18.2535 54.1938 18.3995 53.8881 18.4854C53.5827 18.5713 53.2635 18.5954 52.9488 18.5564C52.6332 18.5994 52.3123 18.5784 52.0052 18.4948C51.6978 18.4111 51.4108 18.2665 51.1607 18.0694C50.9107 17.8724 50.7027 17.6271 50.5496 17.348C50.3962 17.069 50.3008 16.7619 50.2689 16.4451H51.3324C51.3967 17.1277 52.0239 17.5679 52.9488 17.5679C53.8734 17.5679 54.4771 17.0734 54.4771 16.3762C54.4771 15.6692 53.9085 15.2336 52.9148 15.2336H51.9305V14.2649Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M56.8213 16.8602C56.8213 15.891 57.5442 15.3322 58.824 15.2528L60.2824 15.1688V14.704C60.2824 14.1353 59.9076 13.8141 59.1803 13.8141C58.5871 13.8141 58.177 14.032 58.0578 14.4126H57.0291C57.1377 13.4877 58.0089 12.8945 59.2304 12.8945C60.5804 12.8945 61.3417 13.5666 61.3417 14.704V18.3829H60.3174V17.6262H60.2334C60.0627 17.8976 59.8233 18.1189 59.5389 18.2674C59.2547 18.4159 58.9363 18.4864 58.6159 18.4716C58.3901 18.4951 58.1617 18.471 57.9455 18.4008C57.7292 18.3306 57.5303 18.216 57.361 18.064C57.1921 17.9122 57.0568 17.7267 56.9637 17.5193C56.8706 17.312 56.8222 17.0874 56.8213 16.8602ZM60.2824 16.4001V15.9499L58.9675 16.034C58.2259 16.0837 57.8897 16.3358 57.8897 16.8105C57.8897 17.2951 58.31 17.5771 58.8882 17.5771C59.0574 17.5943 59.2287 17.5772 59.3914 17.5269C59.5539 17.4766 59.7047 17.394 59.8349 17.2842C59.9651 17.1744 60.0715 17.0395 60.1485 16.8876C60.2255 16.7357 60.271 16.57 60.2824 16.4001Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M64.162 13.8924V18.3824H63.0995V12.9979H66.6446V13.8923L64.162 13.8924Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M73.0842 15.6885C73.0842 17.3991 72.219 18.4717 70.8596 18.4717C70.526 18.4946 70.1929 18.4215 69.8991 18.2612C69.6056 18.101 69.3639 17.8603 69.2026 17.5673H69.1185V20.1731H68.0558V12.9986H69.0789V13.8884H69.1581C69.3223 13.5852 69.5674 13.3334 69.8657 13.1606C70.164 12.9878 70.5045 12.9008 70.8489 12.9092C72.219 12.9092 73.0842 13.9771 73.0842 15.6885ZM71.9912 15.6885C71.9912 14.5511 71.4528 13.8639 70.5526 13.8639C69.6579 13.8639 69.0987 14.5656 69.0987 15.6932C69.0987 16.8153 69.6579 17.5177 70.5478 17.5177C71.4528 17.5177 71.9912 16.8305 71.9912 15.6885Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M74.5004 20.3109V19.3959C74.6153 19.4106 74.731 19.4171 74.8471 19.4158C75.0735 19.4325 75.2979 19.3651 75.4779 19.2264C75.6574 19.0877 75.7799 18.8876 75.821 18.6643L75.9005 18.4022L73.9513 12.9979H75.1086L76.4586 17.2748H76.5427L77.8878 12.9979H79.0148L77.0074 18.6048C76.5475 19.915 76.038 20.3354 74.9405 20.3354C74.8519 20.3354 74.5994 20.3254 74.5004 20.3109Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M79.8429 16.8541H80.9009C81.0047 17.3289 81.4496 17.5763 82.072 17.5763C82.7795 17.5763 83.214 17.2646 83.214 16.78C83.214 16.2609 82.824 16.0133 82.072 16.0133H81.2662V15.1877H82.0581C82.7003 15.1877 83.0272 14.9408 83.0272 14.4907C83.0272 14.0953 82.7108 13.7935 82.0581 13.7935C81.4638 13.7935 81.0293 14.0458 80.9603 14.4708H79.9363C80.0127 13.9824 80.2779 13.5435 80.6747 13.2486C81.0715 12.9538 81.5682 12.8263 82.0581 12.8938C83.3376 12.8938 84.0701 13.4724 84.0701 14.3821C84.076 14.6354 83.9948 14.8831 83.84 15.0838C83.6852 15.2843 83.4664 15.4258 83.2199 15.4844V15.5685C83.5134 15.6064 83.7828 15.7506 83.977 15.9739C84.1711 16.1973 84.2764 16.4841 84.2733 16.78C84.2733 17.8035 83.4022 18.4807 82.072 18.4807C80.896 18.4807 79.9409 17.9418 79.8429 16.8541Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M85.803 18.3824V12.9979H86.8564V16.7014H86.9405L89.422 12.9979H90.4751V18.3824H89.422V14.6591H89.3424L86.8564 18.3824H85.803Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M96.2775 13.8923H94.582V18.3822H93.5181V13.8923H91.8225V12.9979H96.2775V13.8923Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M102.001 16.9291C101.856 17.4212 101.543 17.8468 101.117 18.1315C100.69 18.4162 100.177 18.542 99.6667 18.4869C99.3121 18.4962 98.9594 18.4283 98.6337 18.2878C98.3076 18.1472 98.0164 17.9375 97.7798 17.6731C97.5432 17.4086 97.3669 17.0959 97.263 16.7565C97.1594 16.4171 97.1309 16.0592 97.1795 15.7077C97.1323 15.355 97.1611 14.9964 97.2647 14.656C97.368 14.3156 97.5435 14.0015 97.779 13.7347C98.0144 13.468 98.3042 13.2549 98.6292 13.1099C98.9541 12.965 99.3064 12.8916 99.6622 12.8946C101.16 12.8946 102.065 13.9182 102.065 15.609V15.9798H98.2632V16.0393C98.2462 16.237 98.2709 16.4359 98.3357 16.6235C98.4002 16.811 98.5035 16.9829 98.6385 17.1282C98.7735 17.2735 98.9374 17.3889 99.1199 17.467C99.3022 17.5452 99.4986 17.5842 99.697 17.5819C99.9511 17.6122 100.208 17.5664 100.436 17.4501C100.664 17.3339 100.852 17.1525 100.977 16.9291H102.001ZM98.2632 15.1939H100.982C100.995 15.0133 100.971 14.8318 100.91 14.6612C100.849 14.4907 100.753 14.3347 100.628 14.2033C100.504 14.072 100.353 13.9682 100.185 13.8985C100.018 13.8289 99.8385 13.7951 99.6574 13.799C99.4737 13.7965 99.2911 13.8309 99.1208 13.9001C98.9504 13.9693 98.7956 14.0721 98.6657 14.202C98.5358 14.3321 98.433 14.4868 98.364 14.6573C98.2949 14.8276 98.2607 15.01 98.2632 15.1939Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M106.507 12.9979H108.934C109.988 12.9979 110.605 13.5316 110.605 14.3671C110.607 14.6322 110.518 14.8898 110.352 15.0968C110.187 15.3038 109.955 15.4478 109.696 15.5044V15.5885C110.01 15.6084 110.305 15.7482 110.519 15.9793C110.734 16.2102 110.851 16.5148 110.847 16.8298C110.847 17.7693 110.141 18.3824 108.959 18.3824H106.507V12.9979ZM107.56 13.8136V15.2079H108.613C109.255 15.2079 109.563 14.9608 109.563 14.5055C109.563 14.0857 109.295 13.8136 108.731 13.8136H107.56ZM107.56 15.9891V17.5667H108.82C109.448 17.5667 109.785 17.2893 109.785 16.7802C109.785 16.2413 109.404 15.9891 108.687 15.9891H107.56Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M64.98 40.5538H61.952L60.2933 35.4721H54.528L52.948 40.5538H50L55.712 23.2534H59.24L64.98 40.5538ZM59.7933 33.3401L58.2933 28.8226C58.1347 28.3611 57.8373 27.2743 57.3987 25.5635H57.3453C57.1707 26.2993 56.8893 27.3861 56.5027 28.8226L55.0293 33.3401H59.7933Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M79.6694 34.1631C79.6694 36.2847 79.0814 37.9617 77.9054 39.1928C76.852 40.2887 75.544 40.836 73.9827 40.836C72.2974 40.836 71.0867 40.2458 70.3494 39.0654H70.296V45.6369H67.4534V32.1858C67.4534 30.852 67.4174 29.4831 67.348 28.0791H69.848L70.0067 30.0564H70.06C71.008 28.5666 72.4467 27.823 74.3774 27.823C75.8867 27.823 77.1467 28.4041 78.1547 29.5676C79.1654 30.7324 79.6694 32.2638 79.6694 34.1631ZM76.7734 34.2645C76.7734 33.0503 76.4934 32.0493 75.9307 31.2615C75.316 30.4399 74.4907 30.0291 73.456 30.0291C72.7547 30.0291 72.1174 30.2579 71.548 30.709C70.9774 31.164 70.604 31.7581 70.4294 32.4939C70.3414 32.8371 70.2974 33.1179 70.2974 33.3389V35.4189C70.2974 36.3263 70.5827 37.092 71.1534 37.7173C71.724 38.3426 72.4654 38.6546 73.3774 38.6546C74.448 38.6546 75.2814 38.2516 75.8774 37.4482C76.4747 36.6435 76.7734 35.5827 76.7734 34.2645Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M94.3853 34.1631C94.3853 36.2847 93.7973 37.9617 92.62 39.1928C91.568 40.2887 90.26 40.836 88.6986 40.836C87.0133 40.836 85.8026 40.2458 85.0666 39.0654H85.0133V45.6369H82.1706V32.1858C82.1706 30.852 82.1346 29.4831 82.0653 28.0791H84.5653L84.724 30.0564H84.7773C85.724 28.5666 87.1626 27.823 89.0946 27.823C90.6026 27.823 91.8626 28.4041 92.8733 29.5676C93.88 30.7324 94.3853 32.2638 94.3853 34.1631ZM91.4893 34.2645C91.4893 33.0503 91.208 32.0493 90.6453 31.2615C90.0306 30.4399 89.208 30.0291 88.172 30.0291C87.4693 30.0291 86.8333 30.2579 86.2626 30.709C85.692 31.164 85.32 31.7581 85.1453 32.4939C85.0586 32.8371 85.0133 33.1179 85.0133 33.3389V35.4189C85.0133 36.3263 85.2986 37.092 85.8666 37.7173C86.4373 38.3413 87.1786 38.6546 88.0933 38.6546C89.164 38.6546 89.9973 38.2516 90.5933 37.4482C91.1906 36.6435 91.4893 35.5827 91.4893 34.2645Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M110.839 35.7023C110.839 37.1739 110.315 38.3712 109.263 39.2955C108.107 40.3056 106.497 40.81 104.429 40.81C102.52 40.81 100.989 40.4512 99.8307 39.7323L100.489 37.4222C101.737 38.158 103.107 38.5272 104.599 38.5272C105.669 38.5272 106.503 38.2906 107.101 37.82C107.697 37.3494 107.995 36.7176 107.995 35.9298C107.995 35.2278 107.749 34.6363 107.257 34.1566C106.768 33.6769 105.951 33.231 104.809 32.8189C101.703 31.6892 100.151 30.0343 100.151 27.8581C100.151 26.4359 100.695 25.2698 101.784 24.3624C102.869 23.4537 104.317 23 106.128 23C107.743 23 109.084 23.2743 110.155 23.8216L109.444 26.081C108.444 25.5506 107.313 25.2854 106.048 25.2854C105.048 25.2854 104.267 25.5259 103.707 26.0043C103.233 26.432 102.996 26.9533 102.996 27.5708C102.996 28.2546 103.267 28.8201 103.811 29.2647C104.284 29.6755 105.144 30.1201 106.392 30.5998C107.919 31.1991 109.04 31.8998 109.761 32.7032C110.48 33.504 110.839 34.5063 110.839 35.7023Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M120.237 30.1591H117.104V36.2158C117.104 37.7563 117.656 38.5259 118.763 38.5259C119.271 38.5259 119.692 38.483 120.025 38.3972L120.104 40.5019C119.544 40.706 118.807 40.8087 117.893 40.8087C116.771 40.8087 115.893 40.4746 115.26 39.8077C114.629 39.1395 114.312 38.0189 114.312 36.4446V30.1565H112.445V28.0765H114.312V25.7924L117.104 24.9708V28.0765H120.237V30.1591Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M134.375 34.2138C134.375 36.1313 133.812 37.7056 132.689 38.9367C131.512 40.2042 129.949 40.836 128.001 40.836C126.124 40.836 124.629 40.2289 123.515 39.0147C122.4 37.8005 121.843 36.2678 121.843 34.4205C121.843 32.4874 122.416 30.904 123.567 29.6729C124.715 28.4405 126.264 27.8243 128.212 27.8243C130.089 27.8243 131.6 28.4314 132.74 29.6469C133.831 30.826 134.375 32.3483 134.375 34.2138ZM131.425 34.3035C131.425 33.153 131.173 32.1663 130.663 31.3434C130.067 30.3476 129.215 29.851 128.111 29.851C126.968 29.851 126.1 30.3489 125.504 31.3434C124.993 32.1676 124.741 33.1699 124.741 34.3555C124.741 35.506 124.993 36.4927 125.504 37.3143C126.119 38.3101 126.977 38.8067 128.085 38.8067C129.171 38.8067 130.023 38.2997 130.637 37.2883C131.161 36.4498 131.425 35.4527 131.425 34.3035Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M143.615 30.5166C143.333 30.4659 143.033 30.4399 142.719 30.4399C141.719 30.4399 140.945 30.8078 140.401 31.5449C139.928 32.1949 139.691 33.0165 139.691 34.0084V40.5539H136.849L136.876 32.0077C136.876 30.5699 136.84 29.2608 136.769 28.0804H139.245L139.349 30.4672H139.428C139.728 29.6469 140.201 28.9865 140.849 28.4912C141.483 28.0453 142.167 27.823 142.904 27.823C143.167 27.823 143.404 27.8412 143.615 27.8737V30.5166Z',
          fill: 'white',
        }),
        _createElementVNode('path', {
          d: 'M156.328 33.7263C156.328 34.2229 156.295 34.6415 156.224 34.9834H147.696C147.729 36.2158 148.141 37.1583 148.933 37.8083C149.652 38.3894 150.581 38.6806 151.723 38.6806C152.985 38.6806 154.137 38.4843 155.173 38.0904L155.619 40.0144C154.408 40.5292 152.979 40.7853 151.329 40.7853C149.345 40.7853 147.788 40.2159 146.655 39.0784C145.524 37.9409 144.957 36.4134 144.957 34.4972C144.957 32.6161 145.484 31.0496 146.539 29.8003C147.643 28.4665 149.135 27.7996 151.012 27.7996C152.856 27.7996 154.252 28.4665 155.2 29.8003C155.951 30.8598 156.328 32.1702 156.328 33.7263ZM153.617 33.0074C153.636 32.1858 153.451 31.476 153.065 30.8767C152.573 30.1058 151.817 29.721 150.8 29.721C149.871 29.721 149.115 30.0967 148.537 30.8507C148.064 31.45 147.783 32.1689 147.696 33.0061H153.617V33.0074Z',
          fill: 'white',
        }),
      ]
    )
  );
}
