export default {
  titles: {
    login: 'Вход',
    hero: 'Получайте и оплачивайте рассрочки быстро и удобно',
    restore: {
      title: 'Восстановление пароля',
      sub: 'Укажите свой номер телефона',
      send: 'На ваш номер телефона был выслан код для восстановления пароля',
      restored: 'По вашему запросу был сброшен пароль для доступа.',
    },
    register: 'Регистрация на сайте',
    registerSuccess: 'Регистрация прошла успешно',
    smsConfirm: 'СМС подтверждение',
    sendCode: 'Отправлено на номер {phone}',
    setPhone: {
      another: 'Указать другой номер',
    },
    setPassword: {
      title: 'Пожалуйста, установите пароль',
      btn: 'Установить пароль',
    },
    hasAccount: {
      title: 'Уже есть аккаунт?',
      btn: 'Вход',
    },
    terms:
      'Продолжая, вы принимаете условия <a class="text-online-500" target="_blank" href="{offerLink}">{offer}</a> ' +
      'и даете согласие на получение <a class="text-online-500" target="_blank" href="{cbReportLink}">{cbReport}</a> о себе',
    termConditions: ['Я согласен с', 'условиями оферты', 'и получением', 'кредитного отчета', 'о себе'],
    updatePhone: {
      passportId: 'Введите серию и номер паспорта',
    },
    ocrFailedAlert: {
      title: 'Не удалось распознать данные паспорта',
    },
    autoAcceptTermConditions: [
      'Авторизуясь, вы соглашаетесь c',
      'условиями оферты',
      'и получением',
      'кредитного отчета',
      'о себе',
    ],
    autoAcceptTermConditions2:
      'Авторизуясь, вы соглашаетесь c <a href="https://alifshop.uz/terms/actual" target="_blank" class="text-primary-600" data-testid="offer_link"> условиями оферты </a> и получением <a href="/pdf/client_agreement.pdf" target="_blank" class="text-primary-600" data-testid="aggrement_link"> кредитного отчета </a> о себе',
  },
  btns: {
    rememberMe: 'Запомнить меня',
  },
  links: {
    requestPassword: 'Забыли пароль?',
    register: ['Нет аккаунта?', 'Регистрация'],
    hasAccount: ['Или', 'войти в аккаунт'],
    login: {
      sms: 'Вход через СМС сообщение',
      password: 'Вход через пароль',
    },
    offer: 'оферты',
    cbReport: 'кредитного отчета',
    comeIn: 'Войти',
    updatePhone: 'Сменить номер',
  },
};
