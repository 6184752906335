<template>
  <div class="home space-y-10 text-gray-900 -mt-12 md:-mt-4 lg:space-y-24">
    <!-- HERO -->
    <div ref="heroBlockRef" class="hero flex flex-col">
      <AtContainer>
        <div class="-mx-4 md:mx-0">
          <div v-if="bannersData" class="relative">
            <BaseCarousel
              v-model="activeSlideBanner"
              loop
              :spacing="16"
              autoplay
              :autoplay-timeout="5000"
              :duration="1000"
              rubberband
              controls
              data-testid="carousel_block"
            >
              <template #before>
                <button
                  v-if="banners.length > 1"
                  class="absolute hidden transform translate-y-2/4 bottom-2/4 z-10 left-6 group duration-300 hover:bg-opacity-100 md:flex p-3 rounded-full bg-white bg-opacity-30"
                  data-testid="carousel_prev_button"
                  @click.stop="clickBannerButton('prev')"
                >
                  <ChevronRightIcon
                    class="duration-300 group-hover:text-gray-900 w-6 h-6 text-white transform rotate-180 hover:bg-opacity-100"
                  />
                </button>
              </template>
              <template #after>
                <button
                  v-if="banners.length > 1"
                  class="absolute hidden bottom-2/4 transform translate-y-2/4 right-6 group hover:bg-opacity-100 md:flex p-3 rounded-full ease-in duration-300 bg-white bg-opacity-30"
                  data-testid="carousel_next_button"
                  @click.stop="clickBannerButton('next')"
                >
                  <ChevronRightIcon class="duration-300 group-hover:text-gray-900 w-6 h-6 text-white" />
                </button>
              </template>
              <template v-for="(item, index) in banners" :key="index">
                <BaseCarouselSlide class="bg-gray-200 md:rounded-3xl min-h-fit" data-testid="carusel_slide_block">
                  <a
                    :href="item.link"
                    tabindex="-1"
                    target="_blank"
                    class="w-full align-middle flex image-height-media h-full"
                  >
                    <picture class="w-full">
                      <source
                        :srcset="item?.web?.link"
                        media="(min-width: 1024px)"
                        class="object-cover align-middle h-full w-full md:rounded-3xl"
                      />
                      <img
                        :src="item?.mobile?.link"
                        alt=""
                        class="object-cover align-middle h-full w-full md:rounded-3xl"
                      />
                    </picture>
                  </a>
                </BaseCarouselSlide>
              </template>
            </BaseCarousel>
            <div
              v-if="banners.length > 1"
              class="gap-2 w-full h-auto flex justify-center absolute bottom-4 left-0 z-10"
            >
              <span
                v-for="(_, index) in banners.length"
                :key="index"
                class="w-4 h-1 bg-white bg-opacity-70 rounded-sm"
                :class="{ 'bg-primary-600': activeSlideBanner === index }"
                @click.stop="activeSlideBanner = index"
              >
              </span>
            </div>
          </div>
          <div v-else>
            <AtSkeleton class="image-height-media md:rounded-3xl" />
          </div>
        </div>
      </AtContainer>
      <AtContainer v-if="!isAuth" class="w-full flex mt-8 space-x-3 sm:hidden">
        <AtButton :to="{ name: AUTH_COMPONENT_NAME }" class="text-xs" block size="sm" data-testid="login_by_sms_button">
          {{ $t('system.btns.login') }}
        </AtButton>
      </AtContainer>
      <!-- FEATURES -->
      <AtContainer class="grid mt-8 grid-cols-2 gap-2 lg:grid-cols-4 lg:gap-6">
        <div v-for="(feature, index) in features" :key="index" class="col-span-1 border rounded-3xl">
          <div class="flex flex-col">
            <div class="p-3 pb-2.5 w-100 flex items-center xs:justify-start lg:justify-center lg:pt-6 pb-2.5">
              <div :class="feature.iconBg" class="p-2 rounded-xl">
                <component :is="feature.icon" />
              </div>
            </div>
            <div class="pb-6 pl-3 pr-2.5 md:px-3 lg:px-6">
              <p class="text-sm leading-5 font-medium xs:text-left lg:text-center lg:text-base">
                {{ $t(feature.title) }}
              </p>
              <!-- <p class="text-lg" v-html="feature.description"></p> -->
            </div>
          </div>
        </div>
      </AtContainer>
      <!-- FEATURES END -->
    </div>
    <!-- HERO END -->

    <!-- REPAYS-CAROUSEL -->
    <AtContainer>
      <h3 class="mb-8 text-4xl leading-10 font-bold" data-testid="carousel_title">
        {{ $t('landing.titles.repays_carousel_title') }}
      </h3>
      <div class="overflow-hidden">
        <BaseCarousel
          style="overflow: visible"
          :breakpoints="repayBreakpoints"
          :slides-per-view="1.05"
          :spacing="16"
          rubberband
          controls
        >
          <BaseCarouselSlide v-for="(item, idx) in repaysCarusel" :key="idx">
            <div :class="item.bgColor" class="rounded-3xl flex justify-center">
              <div class="pt-7 md:pt-6">
                <img :src="item.image" alt="" data-testid="carousel_image" />
              </div>
            </div>
            <div class="mt-4">
              <p class="text-base leading-6 font-medium lg:text-lg lg:font-normal" data-testid="carousel_description">
                {{ $t(item.description) }}
              </p>
            </div>
          </BaseCarouselSlide>
        </BaseCarousel>
      </div>
    </AtContainer>
    <!--REPAYS-CAROUSEL END -->
    <!-- REPAYS -->
    <AtContainer class="repays">
      <h3 class="text-4xl leading-10 mb-8 font-bold max-w-lg">
        {{ $t('landing.titles.repays') }}
      </h3>
      <div class="grid grid-cols-1 gap-3 md:grid-cols-3">
        <div
          v-for="(repay, index) in repaysVariable"
          :key="index"
          class="col-span-1 bg-gray-100 p-3.5 pr-2.5 rounded-2xl lg:p-6"
        >
          <div class="flex mb-2.5 gap-x-3">
            <component :is="icon" v-for="(icon, idx) in repay.icons" :key="idx" />
          </div>
          <div>
            <p class="text-base font-medium leading-6">{{ $t(repay.description) }}</p>
            <div v-if="repay.srcTitle && repay.src" class="flex mt-2.5 lg:hidden">
              <a class="flex items-center text-green-600" :href="repay.src" target="_blank">
                <span> {{ $t(repay.srcTitle) }}</span>
                <component :is="repay.srcIcon" v-if="repay.srcIcon" class="w-5 ml-2.5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </AtContainer>
    <!-- REPAYS END -->
    <!-- STORES -->
    <AtContainer class="stores space-y-6 md:space-y-8">
      <h3 class="text-4xl leading-10 font-bold max-w-md" data-testid="partners_name">
        {{ $t('landing.titles.merchants') }}
      </h3>
      <div class="mt-5 flex flex-nowrap gap-2 md:gap-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        <router-link
          v-for="(brand, index) in brands"
          :key="index"
          :to="{ name: 'Shops.Show', params: { shopId: brand.id } }"
          class="h-24 sm:h-28 flex w-full flex-shrink-0 md:w-auto md:flex-shrink justify-center items-center bg-gray-100 rounded-2xl md:rounded-3xl overflow-hidden"
        >
          <img
            :src="brand.file"
            alt=""
            class="mix-blend-multiply object-cover h-auto w-full"
            data-testid="brand_image"
          />
        </router-link>
      </div>
      <div class="flex">
        <AtButton
          :to="{ name: 'Shops.Index' }"
          color="primary"
          class="w-full md:w-auto"
          size="lg"
          data-testid="shops_link"
        >
          {{ $t('landing.buttons.see_all_merchants') }}
        </AtButton>
      </div>
    </AtContainer>
    <!-- STORES END -->

    <!-- MOBILE APP -->
    <div class="mobile-app gradient-green py-10 overflow-hidden">
      <AtContainer class="lg:flex lg:justify-between lg:items-center space-y-14 lg:space-y-14">
        <div class="space-y-8 lg:space-y-10">
          <h3 class="text-center lg:text-left font-extrabold text-2xl lg:text-5xl leading-none">
            {{ $t('landing.titles.download_app') }}
          </h3>
          <div class="flex justify-center lg:justify-start gap-2 sm:gap-6">
            <a
              class="w-40 sm:w-auto"
              href="https://play.google.com/store/apps/details?id=tj.alif.mobi"
              data-testid="google_play_icon"
            >
              <GooglePlayIcon class="max-w-full" />
            </a>
            <a class="w-40 sm:w-auto" href="https://apps.apple.com/app/id1331374853?mt=8" data-testid="app_store_icon">
              <AppStoreIcon class="max-w-full" />
            </a>
          </div>
        </div>
        <div class="relative flex justify-center lg:mr-20">
          <div class="flex relative -mb-10">
            <img src="../assets/images/mock-1.png" alt="mobi-app" class="hidden lg:block" />
            <!-- <img
              src="../assets/images/mock-2.png"
              alt="mobi-app"
              class="hidden lg:block absolute -bottom-7 left-32"
            /> -->
            <div class="lg:hidden z-10 relative">
              <img src="../assets/images/mock-3.png" alt="mobi-app" class="w-full h-full" />
            </div>
          </div>
          <!-- <div
            class="-mb-16 w-80 border-6 border-gray-100 bg-white rounded-2xl py-12 flex flex-col items-center space-y-10 px-9"
          >
            <BaseLogo />
            <AppQrCodeBracketIcon class="hidden lg:block" />
            <p class="text-center hidden lg:block">
              {{ $t("landing.titles.scan_qr") }}
            </p>
          </div> -->
        </div>
      </AtContainer>
    </div>
    <!-- MOBILE APP END -->
    <!-- FAQs -->
    <AtContainer>
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <AppFAQ />
      </div>
    </AtContainer>
    <!-- FAQs END -->

    <!-- FEEDBACK -->
    <div class="bg-gray-100 py-12">
      <AtContainer>
        <div class="text-center space-y-8">
          <h3 class="text-4xl leading-10 font-extrabold" data-testid="title_footer">
            {{ $t('landing.titles.become_partner') }}
          </h3>
          <a
            :href="`${merchantUrl}/sign-up`"
            class="inline-flex items-center px-4 py-2 border-none text-base font-medium rounded-md shadow-sm text-white bg-primary-600"
            data-testid="contact_us_button"
          >
            {{ $t('landing.buttons.contact_us') }}
          </a>
        </div>
      </AtContainer>
    </div>
    <!-- FEEDBACK END -->
  </div>
</template>

<script setup>
  // @ is an alias to /src
  import { computed, inject, onMounted, reactive, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { ChevronRightIcon } from '@heroicons/vue/24/outline';

  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtSkeleton from '~/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton';

  import BannersLoader from '~/assets/banners/BannerLoader.svg';
  import MobileDefaultBannerRu from '~/assets/banners/Mobile-Default-Banner-Ru.png';
  import MobileDefaultBannerUz from '~/assets/banners/Mobile-Default-Banner-Uz.png';
  import WebDefaultBannerRu from '~/assets/banners/Web-Default-Banner-Ru.png';
  import WebDefaultBannerUz from '~/assets/banners/Web-Default-Banner-Uz.png';
  import ClickIcon from '~/assets/icons/ClickIcon.vue';
  import PaymeIcon from '~/assets/icons/PaymeIcon.vue';
  import PaynetIcon from '~/assets/icons/PaynetIcon.vue';

  import AppFAQ from '~/components/app/AppFAQ.vue';
  import BaseCarousel from '~/components/base/BaseCarousel/BaseCarousel.vue';
  import BaseCarouselSlide from '~/components/base/BaseCarousel/BaseCarouselSlide.vue';
  import AppStoreIcon from '~/components/icons/AppStoreIcon';
  import Cart from '~/components/icons/Cart';
  import ChartPie from '~/components/icons/ChartPieIcon';
  import DiscountIcon from '~/components/icons/DiscountIcon';
  import GooglePlayIcon from '~/components/icons/GooglePlayIcon';
  import RectangleIcon from '~/components/icons/RectangleIcon';
  import AtContainer from '~/components/ui/AtContainer.vue';

  import { AUTH_COMPONENT_NAME } from '~/constants';

  import { useService } from '~/hooks/useService';

  import { getBanners } from '~/services/public.api';

  import { $_at_user } from '~/shared/at-user';

  import ElmakonImg from '../assets/images/brands/Elmakon.png';
  import GoodzoneImg from '../assets/images/brands/Goodzone.png';
  import MacbroImg from '../assets/images/brands/Macbro.png';
  import MediaparkImg from '../assets/images/brands/Mediapark.png';
  import MobilezoneImg from '../assets/images/brands/Mobilezone.png';
  import ParagrafImg from '../assets/images/brands/Paragraf.png';
  import RadiusImg from '../assets/images/brands/Radius.png';
  import StoresmartImg from '../assets/images/brands/Storesmart.png';
  import TexnomartImg from '../assets/images/brands/Texnomart.png';
  import IdeaImg from '../assets/images/brands/idea.png';
  // RepaysImage
  import BlueBlockImage from '../assets/images/landing-repays-carousel/Blue.svg';
  import GreenBlockImage from '../assets/images/landing-repays-carousel/Green.svg';
  import PinkBlockImage from '../assets/images/landing-repays-carousel/Pink.svg';
  // RepaysIcon
  import AlifMobileIcon from '../components/icons/AlifMobileIcon.vue';

  const defaultBannerImages = {
    ru: {
      web: WebDefaultBannerRu,
      mobile: MobileDefaultBannerRu,
    },
    uz: {
      web: WebDefaultBannerUz,
      mobile: MobileDefaultBannerUz,
    },
  };

  const activeSlideBanner = ref(0);

  const service = {
    getBanners: reactive(useService(getBanners)),
  };
  const isAuth = computed(() => Boolean($_at_user.value));
  onMounted(async () => {
    await service.getBanners.execute();
  });

  const { locale } = useI18n();

  const banners = ref([]);

  const bannersData = computed(() => {
    return service.getBanners.response?.data.map(el => {
      const filterFiles = el.files.filter(file => file.file_type.includes(locale.value.toUpperCase()));
      const mobile = filterFiles.find(el => el.file_type.includes('MOBILE'));
      const web = filterFiles.find(el => el.file_type.includes('WEB'));
      return { link: el.link, order: el.order, web, mobile };
    });
  });

  const checkBannerImage = ({ el, idx, original }, type) => {
    const image = new Image();
    image.src = el?.[type]?.link;
    image.onerror = () => {
      banners.value[idx][type].link = defaultBannerImages[locale.value][type];
      banners.value[idx].link = null;
    };
    image.onload = () => {
      banners.value[idx][type].link = original;
    };
  };

  watch(bannersData, () => {
    banners.value = bannersData.value.map((el, idx) => {
      let webOriginal = el?.web?.link;
      let mobileOriginal = el?.mobile?.link;

      if (el.web.link) checkBannerImage({ el, idx, original: webOriginal }, 'web');
      if (el.mobile.link) checkBannerImage({ el, idx, original: mobileOriginal }, 'mobile');

      return { ...el, web: { ...el.web, link: BannersLoader }, mobile: { ...el.mobile, link: BannersLoader } };
    });
  });

  const repayBreakpoints = {
    '(min-width: 380px)': {
      slidesPerView: 1.3,
      spacing: 24,
    },
    '(min-width: 500px)': {
      slidesPerView: 1.5,
      spacing: 24,
    },
    '(min-width: 640px)': {
      slidesPerView: 1.9,
      spacing: 24,
    },
    '(min-width: 768px)': {
      slidesPerView: 2.1,
      spacing: 24,
    },
    '(min-width: 850px)': {
      slidesPerView: 2.4,
      spacing: 24,
    },
    '(min-width: 1024px)': {
      slidesPerView: 3,
    },
  };

  const repaysCarusel = [
    {
      image: BlueBlockImage,
      bgColor: 'bg-blue-100',
      description: 'landing.repays_carousel[0]',
    },
    {
      image: PinkBlockImage,
      bgColor: 'bg-pink-100',
      description: 'landing.repays_carousel[1]',
    },
    {
      image: GreenBlockImage,
      bgColor: 'bg-green-100',
      description: 'landing.repays_carousel[2]',
    },
  ];

  const repaysVariable = [
    {
      description: 'landing.repays.repay1_description',
      icons: [AlifMobileIcon],
      src: 'https://app.adjust.com/97orbzg?campaign=mobi&adgroup=nasiya&creative=landing',
      srcTitle: 'landing.repays.repay1_src_title',
      srcIcon: ChevronRightIcon,
    },
    {
      description: 'landing.repays.repay2_description',
      icons: [ClickIcon, PaymeIcon],
    },
    {
      description: 'landing.repays.repay3_description',
      icons: [PaynetIcon],
    },
  ];

  const features = [
    {
      title: 'landing.steps.step1',
      iconBg: 'bg-blue-100',
      icon: RectangleIcon,
    },
    {
      title: 'landing.steps.step2',
      iconBg: 'bg-purple-100',
      icon: ChartPie,
    },
    {
      title: 'landing.steps.step3',
      iconBg: 'bg-green-100',
      icon: Cart,
    },
    {
      title: 'landing.steps.step4',
      iconBg: 'bg-pink-100',
      icon: DiscountIcon,
    },
  ];

  const brands = [
    {
      file: IdeaImg,
      id: 3,
    },
    {
      file: MediaparkImg,
      id: 231,
    },
    {
      file: StoresmartImg,
      id: 4,
    },
    {
      file: ParagrafImg,
      id: 67,
    },
    {
      file: MobilezoneImg,
      id: 33,
    },
    {
      file: GoodzoneImg,
      id: 444,
    },
    {
      file: ElmakonImg,
      id: 634,
    },
    {
      file: RadiusImg,
      id: 552,
    },
    {
      file: TexnomartImg,
      id: 484,
    },
    {
      file: MacbroImg,
      id: 791,
    },
  ];

  const heroBlockRef = ref(null);

  const emit = defineEmits(['intersectionLandingHeroBlock']);

  function clickBannerButton(direction) {
    if (direction === 'next') {
      if (activeSlideBanner.value === bannersData.value.length - 1) return (activeSlideBanner.value = 0);
      activeSlideBanner.value++;
    }
    if (direction === 'prev') {
      if (activeSlideBanner.value === 0) return (activeSlideBanner.value = bannersData.value.length - 1);
      activeSlideBanner.value--;
    }
  }

  const merchantUrl = inject('merchantUrl');

  onMounted(() => {
    emit('intersectionLandingHeroBlock', false);
    if (
      !('IntersectionObserver' in window) &&
      !('IntersectionObserverEntry' in window) &&
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
      emit('intersectionLandingHeroBlock', false);
    } else {
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      };
      const observer = new IntersectionObserver(
        ([entries]) => emit('intersectionLandingHeroBlock', entries.isIntersecting),
        options
      );
      observer.observe(heroBlockRef.value);
    }
  });
</script>
<style lang="scss">
  .hero {
    &__title {
      font-size: 40px;
      line-height: 50.4px;
    }
  }
  .image-height-media {
    max-height: 146px;

    @media (min-width: 400px) {
      max-height: 196px;
      min-height: 146px;
    }

    @screen sm {
      max-height: 245px;
      min-height: 196px;
    }
    @screen lg {
      max-height: 315px;
      min-height: 245px;
    }
    @screen xl {
      max-height: 392px;
      min-height: 315px;
    }
  }
</style>
