<script setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="7.2" fill="#37A134" />
    <path
      d="M24.607 40.7271H24.5767H24.5678C21.2808 40.3969 18.1667 39.0948 15.6229 36.9872C13.079 34.8795 11.2208 32.0617 10.2852 28.8934C9.34968 25.7251 9.37922 22.35 10.3701 19.1985C11.361 16.0471 13.2682 13.2623 15.8486 11.1995L15.8822 11.1704C18.4953 9.02261 21.6593 7.65229 25.0135 7.21563L25.167 7.19995L26.5961 10.719L26.4382 10.7313C23.4647 10.9163 20.6219 12.019 18.3014 13.8875L18.1894 13.9748C18.1323 14.0208 18.0774 14.0678 18.0191 14.116L17.9755 14.1518C15.9907 15.7884 14.5368 17.9777 13.7982 20.4418C13.0596 22.906 13.0695 25.534 13.8268 27.9925C14.584 30.4511 16.0544 32.6293 18.0514 34.2508C20.0485 35.8724 22.4822 36.8643 25.0438 37.1006C28.7765 37.4118 32.4836 36.2559 35.378 33.8783C35.5132 33.7663 35.6472 33.6521 35.7801 33.5356L37.2115 37.0603C33.6208 39.8246 29.1201 41.1339 24.607 40.7271ZM28.4239 15.4779H23.9899L18.0763 30.0311H21.8697L26.2063 18.7908L30.543 30.0311H34.3364L28.4239 15.4779Z"
      fill="white"
    />
  </svg>
</template>

<style></style>
