import {
  createElementBlock as _createElementBlock,
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '32',
        height: '32',
        viewBox: '0 0 32 32',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          d: 'M17.6667 18.6667C17.6667 17.9303 18.1144 17.3333 18.6667 17.3333H23C23.5523 17.3333 24 17.9303 24 18.6667C24 19.4031 23.5523 20 23 20H18.6667C18.1144 20 17.6667 19.4031 17.6667 18.6667Z',
          fill: '#3B82F6',
        }),
        _createElementVNode('path', {
          d: 'M3.56745 13.3334H28.4326M8.90072 25.9461H23.0993C26.0448 25.9461 28.4326 23.5583 28.4326 20.6127V11.3872C28.4326 8.44171 26.0448 6.05389 23.0993 6.05389H8.90072C5.9552 6.05389 3.56738 8.44171 3.56738 11.3872V20.6127C3.56738 23.5583 5.9552 25.9461 8.90072 25.9461Z',
          stroke: '#1F2937',
          'stroke-width': '2.5',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
      ]
    )
  );
}
